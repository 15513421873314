import { SettingsComponent } from './settings/settings.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../../_modules/shared/shared.module';
import { RouterModule } from '@angular/router';
import { LoginService } from './login/login.service';
import { Page403Component } from './page403/page403.component';

@NgModule({
	imports: [CommonModule, SharedModule, RouterModule],
	declarations: [
		LoginComponent,
		Page403Component,
		PrivacyPolicyComponent,
		TermsOfUseComponent,
		SettingsComponent
	],
	exports: [LoginComponent, SettingsComponent],
	providers: [LoginService]
})
export class PagesModule {}
