import {
	MissingTranslationHandler,
	MissingTranslationHandlerParams
} from '@ngx-translate/core';

interface InterpolateParams {
	default: any;
}

export class DefaultMissingTranslationHelper
	implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams) {
		if (params.interpolateParams) {
			return (
				(params.interpolateParams as InterpolateParams).default || params.key
			);
		}
		return params.key;
	}
}
