import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@services/logger/logger.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AutoUnsubscribe } from '../../../_decorators/autounsub';
import { LogInit } from '../../../_decorators/loginit';
import { LoginUtils } from '../../../_services/loginutils/loginutils.service';
import { LoginService } from './login.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
@AutoUnsubscribe()
@LogInit()
export class LoginComponent implements OnInit {
	public passwordRequirements;

	loginForm: FormGroup;
	passwordResetForm: FormGroup;

	passwordResetFormErrors: any;

	pending = false;
	loginError: any;

	showPasswordReset = false;
	passwordResetResult = false;

	public submitLogin$: Subscription;
	public submitReset$: Subscription;

	public langs = environment.availableLangs;
	public get currentLang() {
		return this.translate.currentLang;
	}

	constructor(
		private fb: FormBuilder,
		private service: LoginService,
		private log: LoggerService,
		private loginutils: LoginUtils,
		private translate: TranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService
	) {
		this.translate
			.get('login.setNewPasswordPage.passwordHintDetails')
			.subscribe((res: string) => {
				this.passwordRequirements = res.split(',');
			});

		this.loginForm = this.fb.group({
			username: [null, Validators.required],
			password: [null, Validators.required]
		});

		this.passwordResetForm = this.fb.group({
			newPassword: [null, Validators.required],
			token: [null, Validators.required]
		});
	}

	ngOnInit() {
		this.log.debug('Pages / LoginComponent', this);
		if (this.loginutils.isLogged) {
			this.router.navigate(['/']);
		}
		if (this.route.snapshot.params.token) {
			this.initPasswordReset();
		}
	}

	changeLang(lang) {
		this.loginutils.useLang(lang);
	}

	submit($event) {
		$event.preventDefault();
		const { username, password } = this.loginForm.value;
		this.pending = true;
		this.submitLogin$ = this.service
			.login(username, password)
			.subscribe(res => this.loginOk(res), err => this.loginErr(err));
	}

	submitPasswordReset($event) {
		$event.preventDefault();
		this.pending = true;
		this.submitReset$ = this.service
			.sendNewPassword(this.passwordResetForm.value)
			.subscribe(
				res => {
					this.passwordResetFormErrors = null;
					// this.toastr.success(this.translate.instant('general.newPasswordSet'));
					this.pending = false;
					this.showPasswordReset = false;
					this.passwordResetResult = true;
				},
				err => {
					this.pending = false;
					if (err.error.fieldErrors) {
						this.passwordResetFormErrors = err.error.fieldErrors;
					} else if (
						err.error.code === 409 &&
						err.error.resultKey === 'PASSWORD_TOKEN_IS_INVALID'
					) {
						this.toastr.error(
							this.translate.instant('globalErrors.INVALID_RESET_LINK'),
							this.translate.instant('general.newPasswordSetFailure')
						);
					} else {
						this.toastr.error(
							err.error.errorDetails,
							this.translate.instant('general.newPasswordSetFailure')
						);
					}
				}
			);
	}

	initPasswordReset() {
		this.showPasswordReset = true;
		this.passwordResetForm.patchValue({
			token: this.route.snapshot.params.token
		});
	}

	loginOk(res) {
		this.loginutils.user = res.user;
		this.router.navigate(['/']);
	}

	loginErr(err) {
		this.loginError = 'login.badLoginOrPass';
		this.pending = false;
	}
}
