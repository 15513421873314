import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {
	transform(value: any, replaceSymbol: string = ''): any {
		if (value !== undefined && value !== null) {
			return value.replace(/,/g, replaceSymbol);
		}
		return '';
	}
}
