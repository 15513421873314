import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoggerService } from '@services/logger/logger.service';

@Injectable({
	providedIn: 'root'
})
export class PreloadStrategyService implements PreloadingStrategy {
	constructor(private log: LoggerService) {}
	preloadedModules: string[] = [];

	preload(route: Route, load: () => Observable<any>): Observable<any> {
		if (route.data && route.data.preload) {
			this.preloadedModules.push(route.path);
			this.log.debug('📦 preloaded: ' + route.path);
			return load();
		} else return of(null);
	}
}
