<ng-container *ngIf="type !== 'SINGLE_IMAGE'; else singleImage">
	<div class="mb-3 pt-3">
		<button
			class="btn btn-sm btn-primary"
			(click)="fileinput.click()"
			[disabled]="resetLocal"
		>
			{{ 'files.upload' | translate }}
		</button>
		<input
			id="nfile"
			type="file"
			class="d-none"
			#fileinput
			(change)="updateFiles($event)"
			[multiple]="multiple"
			[accept]="acceptString"
		/>
	</div>

	<ng-container *ngIf="files.length && !resetLocal">
		<div class="d-flex">
			<div class="media d-block m-auto" *ngFor="let file of files">
				<img
					class="mb-3"
					[src]="file.preview"
					[alt]="file.name"
					*ngIf="file.preview"
					style="max-width: 150px;"
				/>
				<div class="media-body d-block">
					<h5 class="mt-0">{{ file.name }}</h5>
					{{ getMB(file.size).toFixed(2) + ' MB' }}
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>
<ng-template #singleImage>
	<div class="mb-3">
		<div class="image-container d-inline-block">
			<img
				[src]="imageSource ? imageSource : placeholderImageSrc"
				class="image-uploader-button"
				[class.image-selected]="!!imageSource"
				(click)="fileinput.click()"
			/>
			<input
				id="nfile"
				type="file"
				class="d-none"
				#fileinput
				(change)="updateFiles($event)"
				[multiple]="multiple"
				[accept]="acceptString"
			/>
			<div class="d-flex justify-content-between align-items-center">
				<label class="m-0">{{
					'resources.thumbnailRequirements' | translate
				}}</label>
				<button class="btn btn-action p-0" (click)="resetImage()">
					{{ 'common.reset' | translate }}
				</button>
			</div>
		</div>
	</div>
</ng-template>
