<div ngbDropdown class="choresmiths-dropdown">
	<button
		class="btn btn-dropdown"
		ngbDropdownToggle
		[disabled]="disabled"
		(click)="expand()"
		(blur)="focusLost.emit()"
	>
		<div *ngIf="selectedItem; else parentPlaceholder">
			{{ selectedItem.label }}
		</div>
		<ng-template #parentPlaceholder>
			{{ placeholder }}
		</ng-template>
		<img src="./../../../assets/images/icons/keyboard-arrow-down.svg" />
	</button>
	<div
		ngbDropdownMenu
		class="dropdown-items"
		[ngClass]="{ 'scrollable-items': scrollableItems }"
		[style.max-height.px]="scrollTresholdHeight"
	>
		<ng-container *ngIf="items && items.length; else noItems">
			<button
				ngbDropdownItem
				class=""
				*ngFor="let item of items"
				(click)="selectItem(item)"
			>
				<div>{{ item.label }}</div>
			</button>
		</ng-container>
		<ng-template #noItems>
			<button ngbDropdownItem disabled>
				{{ 'common.emptyList' | translate }}
			</button>
		</ng-template>
	</div>
</div>
