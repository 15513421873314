/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NamedRoleIdDto } from './namedRoleIdDto';

/**
 * Admin user saving data used for creating and editing
 */
export interface AdminUserSaveDto {
	email: string;
	fullName: string;
	/**
	 * Required for updating
	 */
	id?: number;
	/**
	 * Required for creating. Optional for updating
	 */
	password?: string;
	role: AdminUserSaveDto.RoleEnum;
	uniqueEmail?: NamedRoleIdDto;
}
export namespace AdminUserSaveDto {
	export type RoleEnum =
		| 'SYSTEM'
		| 'ROOT'
		| 'SUPERADMIN'
		| 'ADMIN'
		| 'PROVIDER'
		| 'CLIENT'
		| 'PROVIDER_REGISTRATION';
	export const RoleEnum = {
		SYSTEM: 'SYSTEM' as RoleEnum,
		ROOT: 'ROOT' as RoleEnum,
		SUPERADMIN: 'SUPERADMIN' as RoleEnum,
		ADMIN: 'ADMIN' as RoleEnum,
		PROVIDER: 'PROVIDER' as RoleEnum,
		CLIENT: 'CLIENT' as RoleEnum,
		PROVIDERREGISTRATION: 'PROVIDER_REGISTRATION' as RoleEnum
	};
}
