import { DecimalPipe } from '@angular/common';
import {
	DEFAULT_CURRENCY_CODE,
	Inject,
	Pipe,
	PipeTransform
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNumber } from 'lodash';

@Pipe({
	name: 'localCurrency'
})
export class LocalCurrencyPipe implements PipeTransform {
	constructor(
		private ts: TranslateService,
		@Inject(DEFAULT_CURRENCY_CODE) private currencyCode: string
	) {}

	transform(value: any, currencySymbol?: string): any {
		if (!isNumber(value)) {
			return 'NaN';
		}
		const numberPipe = new DecimalPipe(this.ts.currentLang);
		return (
			numberPipe.transform(value, '1.2-2') +
			' ' +
			(currencySymbol ?? this.currencyCode)
		);
	}
}
