import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { BrowserModule } from '@angular/platform-browser';
import {
	DEFAULT_CURRENCY_CODE,
	Injector,
	LOCALE_ID,
	NgModule
} from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './_modules/shared/shared.module';
import { LayoutComponent } from './_components/layout/layout.component';
import { RoutesModule } from './_modules/routes/routes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
	TranslateLoader,
	TranslateModule,
	MissingTranslationHandler
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './_modules/core/core.module';
import { SidebarComponent } from './_components/sidebar/sidebar.component';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { DefaultMissingTranslationHelper } from '@services/utils/missing-translation-handler';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent, LayoutComponent, SidebarComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		HttpClientModule,
		SharedModule.forRoot(),
		RoutesModule,
		NgProgressModule,
		NgProgressHttpModule,
		BrowserAnimationsModule,
		CoreModule.forRoot(),
		ToastrModule.forRoot(),
		NgbModule,
		NgxUiLoaderModule.forRoot({
			fgsColor: '#3498db'
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: DefaultMissingTranslationHelper
			}
		})
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'en' },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: 'NGN' }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	static injector: Injector;
	constructor(injector: Injector) {
		AppModule.injector = injector;
	}
}

registerLocaleData(localeEn, 'en-US');
