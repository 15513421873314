import { TranslateService } from '@ngx-translate/core';
import { UserDetailsDto } from '@models/userDetailsDto';
import { UserRowDto } from '@models/userRowDto';

export type Onboardable = UserDetailsDto & UserRowDto;

export const ActionRequiredStatuses = [
	UserRowDto.OnboardingStatusEnum.SCREENINGINPROGRESS,
	UserRowDto.OnboardingStatusEnum.OFFLINEONBOARDINGYETTOSTART,
	UserRowDto.OnboardingStatusEnum.REGISTRATION,
	UserRowDto.OnboardingStatusEnum.SCREENINGYETTOSTART,
	UserRowDto.OnboardingStatusEnum.OFFLINEONBOARDINGINPROGRESS
];

// Relabeled statuses for the status transition dropdown
export const ActionNameStatusRemapped = [
	{
		// Relabel the translation from SCREENING_YET_TO_START to INVITED_FOR_SCREENING
		source: UserRowDto.OnboardingStatusEnum.SCREENINGYETTOSTART,
		remappedLabelKey: 'OnboardingStatusEnum.INVITED_FOR_SCREENING'
	},
	// Relabel the translation from OFFLINE_ONBOARDING_YET_TO_START to SCREENING_ACCEPTED
	{
		source: UserRowDto.OnboardingStatusEnum.OFFLINEONBOARDINGYETTOSTART,
		remappedLabelKey: 'OnboardingStatusEnum.SCREENING_ACCEPTED'
	}
];

const mapStatusTransitions = (
	user: Onboardable,
	translate: TranslateService
) => {
	if (!user.allowedOnboardingStatusTransitions) {
		return [];
	}

	return user.allowedOnboardingStatusTransitions.map(status => {
		const remappedStatusEntry = ActionNameStatusRemapped.find(
			({ source }) => source === status
		);

		return {
			label: translate.instant(
				remappedStatusEntry
					? remappedStatusEntry.remappedLabelKey
					: `OnboardingStatusEnum.${status}`
			),
			value: status
		};
	});
};

export const onboardableUserMapper = (
	user: Onboardable,
	translate: TranslateService
) => ({
	...user,
	isActionNeeded: ActionRequiredStatuses.includes(user.onboardingStatus),
	allowedOnboardingStatusTransitions: mapStatusTransitions(user, translate),
	canChangeOnboardingStatus:
		user.allowedOnboardingStatusTransitions &&
		!!user.allowedOnboardingStatusTransitions.length
});
