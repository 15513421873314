import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserResponseDto } from '@models/models';
import { LoginUtils } from './../_services/loginutils/loginutils.service';

/**
 * Has role directive that is similar to the ngIf directive built into Angular.
 * It allows to render the directed element conditionally based on the role that the logged user has.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[hasRole]'
})
export class HasRoleDirective {
	@Input()
	set hasRole(value: UserResponseDto.RoleEnum | string) {
		const isPrivileged =
			this.loginUtils.isLogged && this.loginUtils.user.role === value;
		if (isPrivileged) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

	constructor(
		private loginUtils: LoginUtils,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {}
}
