import { Injectable, OnDestroy } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { fromEvent, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { UserResponseDto } from '@models/userResponseDto';
import { LoginUtils } from './../loginutils/loginutils.service';

export enum MediaQueryBreakpoint {
	XsDesktop = 1280,
	SmDesktop = 1366,
	Desktop = 1920,
	LgDesktop = 2560,
	XlDesktop = 3840
}

export interface ResponsiveColumn extends TableColumn {
	breakpoint?: MediaQueryBreakpoint;
}

export interface PrivilegedColumn extends ResponsiveColumn {
	role?: UserResponseDto.RoleEnum;
}

@Injectable()
export class UtilsService implements OnDestroy {
	private destroy$: Subject<void> = new Subject();
	private windowResize$ = fromEvent(window, 'resize').pipe(
		startWith(window.innerWidth),
		takeUntil(this.destroy$),
		map(() => window.innerWidth)
	);

	get onWindowResize$() {
		return this.windowResize$;
	}

	constructor(private loginUtils: LoginUtils) {}

	ngOnDestroy() {
		this.destroy$.next();
	}

	columnsForDatatables(columns: Array<TableColumn | PrivilegedColumn> = []) {
		if (!columns.length) return columns;
		return columns
			.map(this.columnsCreator)
			.filter((column: TableColumn & PrivilegedColumn) =>
				column.role ? column.role === this.loginUtils.user.role : true
			);
	}

	private columnsCreator(item) {
		return typeof item === 'string'
			? { name: item }
			: _.has(item, 'name')
			? item
			: { name: 'no-name' };
	}
}

export interface DTColumn {
	name: string;
}

export interface Pageable {
	page?: number;
	size?: number;
	sort?: string;
	ascending?: boolean;
}

export class PageableSort {
	readonly property: string;
	readonly ascending: boolean;
	readonly requestParam: string;

	constructor(property: string, ascending = true) {
		this.property = property;
		this.ascending = ascending;
		this.requestParam = property + ',' + (ascending ? 'asc' : 'desc');
	}
}
