import { Injectable } from '@angular/core';
import { SettingsDto } from './../../../_interfaces/models/settingsDto';
import { HttpAuth } from './../../../_services/httpauth/http-auth.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SettingsManagementService {
	constructor(private http: HttpAuth) {}

	getSettings(): Observable<SettingsDto> {
		return this.http.get('/settings');
	}

	saveSettings(data: SettingsDto): Observable<void> {
		return this.http.put('/settings', data);
	}
}
