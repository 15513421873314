/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryDto } from './categoryDto';
import { ServiceTypesSubcategoryDto } from './serviceTypesSubcategoryDto';

export interface ServiceTypeDto {
	category?: CategoryDto;
	id?: number;
	name: string;
	priceUnit: ServiceTypeDto.PriceUnitEnum;
	subcategory?: ServiceTypesSubcategoryDto;
	subcategoryId: number;
	synonyms: Array<string>;
}
export namespace ServiceTypeDto {
	export type PriceUnitEnum =
		| 'M2'
		| 'HOUR'
		| 'A_PIECE'
		| 'SESSION'
		| 'ITEM'
		| 'PROJECT'
		| 'M2_MONTH'
		| 'TRIP'
		| 'MONTH'
		| 'PHASE'
		| 'DAY';
	export const PriceUnitEnum = {
		M2: 'M2' as PriceUnitEnum,
		HOUR: 'HOUR' as PriceUnitEnum,
		APIECE: 'A_PIECE' as PriceUnitEnum,
		SESSION: 'SESSION' as PriceUnitEnum,
		ITEM: 'ITEM' as PriceUnitEnum,
		PROJECT: 'PROJECT' as PriceUnitEnum,
		M2MONTH: 'M2_MONTH' as PriceUnitEnum,
		TRIP: 'TRIP' as PriceUnitEnum,
		MONTH: 'MONTH' as PriceUnitEnum,
		PHASE: 'PHASE' as PriceUnitEnum,
		DAY: 'DAY' as PriceUnitEnum
	};
}
