<div class="choresmiths-card-modal">
	<div class="d-flex justify-content-between align-items-center">
		<h5 class="my-0">{{ title }}</h5>
		<button class="btn btn-link p-0" (click)="activeModal.dismiss('cancel')">
			<img src="./../../../assets/images/icons/close-gray.svg" />
		</button>
	</div>
	<p class="mb-4 mt-2">{{ description }}</p>
	<div class="d-flex justify-content-end modal-btn-group">
		<button
			class="btn btn-default upper"
			(click)="activeModal.dismiss('cancel')"
		>
			{{ 'common.cancel' | translate }}
		</button>
		<button class="btn btn-primary upper" (click)="activeModal.close('ok')">
			{{ 'common.confirm' | translate }}
		</button>
	</div>
</div>
