import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-server-errors',
	template: `
		<div
			class="bg-danger mb-1 p-2 small rounded text-white server-error-text"
			*ngIf="customError"
		>
			{{ 'validation_errors.' + customError | translate }}
		</div>
	`
})
export class ServerErrorsComponent {
	public customError: string;
	private workingField: string;

	@Input()
	set fieldName(field: string) {
		this.workingField = field;
	}
	@Input()
	set errors(errors: any) {
		const p = e => e.field === this.workingField;
		this.customError = errors && errors.find(p) ? errors.find(p).message : null;
	}
}
