import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginUtils } from '@services/loginutils/loginutils.service';

@Injectable()
export class IsSuperadminGuard implements CanActivate {
	constructor(private loginutils: LoginUtils, private router: Router) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const rule = this.loginutils.isRoleSufficient(['SUPERADMIN']);
		if (!rule) this.router.navigate(['/403']);
		return rule;
	}
}
