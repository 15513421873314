import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from 'app/routes';
import { PagesModule } from 'app/routes/pages/pages.module';
import { PreloadStrategyService } from '../../_services/utils/preload.strategy';

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadStrategyService,
			relativeLinkResolution: 'legacy'
		}),
		PagesModule
	],
	exports: [RouterModule]
})
export class RoutesModule {}
