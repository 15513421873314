/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ValueObjectDtoOfstring {
	value?: ValueObjectDtoOfstring.ValueEnum;
}
export namespace ValueObjectDtoOfstring {
	export type ValueEnum =
		| 'REGISTRATION'
		| 'SCREENING_YET_TO_START'
		| 'SCREENING_IN_PROGRESS'
		| 'SCREENING_REJECTED'
		| 'OFFLINE_ONBOARDING_YET_TO_START'
		| 'OFFLINE_ONBOARDING_IN_PROGRESS'
		| 'OFFLINE_ONBOARDING_REJECTED'
		| 'OFFLINE_ONBOARDING_ACCEPTED'
		| 'ONLINE_ONBOARDING_COMPLETED';
	export const ValueEnum = {
		REGISTRATION: 'REGISTRATION' as ValueEnum,
		SCREENINGYETTOSTART: 'SCREENING_YET_TO_START' as ValueEnum,
		SCREENINGINPROGRESS: 'SCREENING_IN_PROGRESS' as ValueEnum,
		SCREENINGREJECTED: 'SCREENING_REJECTED' as ValueEnum,
		OFFLINEONBOARDINGYETTOSTART: 'OFFLINE_ONBOARDING_YET_TO_START' as ValueEnum,
		OFFLINEONBOARDINGINPROGRESS: 'OFFLINE_ONBOARDING_IN_PROGRESS' as ValueEnum,
		OFFLINEONBOARDINGREJECTED: 'OFFLINE_ONBOARDING_REJECTED' as ValueEnum,
		OFFLINEONBOARDINGACCEPTED: 'OFFLINE_ONBOARDING_ACCEPTED' as ValueEnum,
		ONLINEONBOARDINGCOMPLETED: 'ONLINE_ONBOARDING_COMPLETED' as ValueEnum
	};
}
