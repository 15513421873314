import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoginUtils } from '@services/loginutils/loginutils.service';
import { UserResponseDto } from '@models/userResponseDto';
import { LogoutService } from '../../_services/loginutils/logout.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@services/logger/logger.service';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
	toggleTop;
	toggleLeft = true;
	toggleLeftSticky = false;
	public user: UserResponseDto;
	public appName = environment.appName;

	constructor(
		private log: LoggerService,
		private loginutils: LoginUtils,
		public logout: LogoutService,
		public translate: TranslateService
	) {}

	ngOnInit() {
		this.log.debug('Layout', this);
		this.user = this.loginutils.user;
	}

	toggle() {
		// this.log.log('toggle', this.toggleLeftSticky, window['Modernizr'].touchevents, $event);
		// if (window.Modernizr.touchevents) {
		// 	this.toggleLeftSticky = !this.toggleLeftSticky;
		// } else {
		this.toggleLeft = !this.toggleLeft;
		// }
	}

	togglemain($event) {
		// if ( window.Modernizr.touchevents ) this.toggleLeftSticky = false;
	}
}
