import { fromEvent as observableFromEvent } from 'rxjs';
import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	ElementRef
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env';

export interface IFile extends File {
	preview?: string | ArrayBuffer | SafeUrl;
}

export type FileUploadType = 'SINGLE_IMAGE' | 'GENERAL';

const images: string[] = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];
const fileSizeMB: number = environment.defaultMaxFileSizeMB;
const fileIcon: string =
	'data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvY' +
	'mUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3' +
	'cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk' +
	'9IjBweCIgdmlld0JveD0iMCAwIDYwIDYwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA2MCA2MDsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIz' +
	'MnB4IiBoZWlnaHQ9IjMycHgiPgo8Zz4KCTxwYXRoIGQ9Ik00Mi41LDIyaC0yNWMtMC41NTIsMC0xLDAuNDQ3LTEsMXMwLjQ0OCwxLDEsMWgyNWMwLjU1MiwwLDEtMC40N' +
	'DcsMS0xUzQzLjA1MiwyMiw0Mi41LDIyeiIgZmlsbD0iIzAwMDAwMCIvPgoJPHBhdGggZD0iTTE3LjUsMTZoMTBjMC41NTIsMCwxLTAuNDQ3LDEtMXMtMC40NDgtMS0xLT' +
	'FoLTEwYy0wLjU1MiwwLTEsMC40NDctMSwxUzE2Ljk0OCwxNiwxNy41LDE2eiIgZmlsbD0iIzAwMDAwMCIvPgoJPHBhdGggZD0iTTQyLjUsMzBoLTI1Yy0wLjU1MiwwLTE' +
	'sMC40NDctMSwxczAuNDQ4LDEsMSwxaDI1YzAuNTUyLDAsMS0wLjQ0NywxLTFTNDMuMDUyLDMwLDQyLjUsMzB6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8cGF0aCBkPSJNNDIu' +
	'NSwzOGgtMjVjLTAuNTUyLDAtMSwwLjQ0Ny0xLDFzMC40NDgsMSwxLDFoMjVjMC41NTIsMCwxLTAuNDQ3LDEtMVM0My4wNTIsMzgsNDIuNSwzOHoiIGZpbGw9IiMwMDAwM' +
	'DAiLz4KCTxwYXRoIGQ9Ik00Mi41LDQ2aC0yNWMtMC41NTIsMC0xLDAuNDQ3LTEsMXMwLjQ0OCwxLDEsMWgyNWMwLjU1MiwwLDEtMC40NDcsMS0xUzQzLjA1Miw0Niw0Mi' +
	'41LDQ2eiIgZmlsbD0iIzAwMDAwMCIvPgoJPHBhdGggZD0iTTM4LjkxNCwwSDYuNXY2MGg0N1YxNC41ODZMMzguOTE0LDB6IE0zOS41LDMuNDE0TDUwLjA4NiwxNEgzOS4' +
	'1VjMuNDE0eiBNOC41LDU4VjJoMjl2MTRoMTR2NDJIOC41eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjxn' +
	'Pgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+C' +
	'jwvc3ZnPgo=';

@Component({
	selector: 'app-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
	@ViewChild('fileinput') fileInputLocal: ElementRef;

	public resetLocal = false;
	public allowedLocal: string[] = null;
	public acceptString: string;
	public files: IFile[] = [];
	public maxMb: number;
	public uploading = false;

	@Input() multiple = false;

	@Input() type: FileUploadType;

	@Input()
	set allowed(t: string[]) {
		this.allowedLocal = Array.isArray(t) ? t : null;
	}

	@Input()
	set reset(b: boolean) {
		this.resetLocal = b;
		if (b) {
			this.fileInputLocal.nativeElement.value = '';
			this.files = [];
			this.fileChanges.next(null);
		}
	}

	@Input()
	set maxSizeMB(size: number) {
		if (!!size) {
			this.maxMb = size;
		}
	}

	@Output() fileChanges: EventEmitter<File[] | string> = new EventEmitter();
	@Output() fileReset: EventEmitter<void> = new EventEmitter();

	private inputSrc: string;
	@Input()
	set src(value: string) {
		this.inputSrc = value;
	}

	get src() {
		return this.inputSrc;
	}

	placeholderImageSrc = './../../../assets/images/placeholder.jpg';
	get imageSource() {
		if (this.type === 'SINGLE_IMAGE' && this.files && this.files[0]) {
			return this.files[0].preview;
		} else if (this.src) {
			return this.src;
		}
		return null;
	}

	constructor(
		private sanitizer: DomSanitizer,
		private toastr: ToastrService,
		private translate: TranslateService
	) {
		this.maxMb = fileSizeMB;
	}

	ngOnInit() {
		this.combineAllowed();
	}

	resetImage() {
		this.reset = true;
		this.src = null;
		this.fileReset.emit();
	}

	private combineAllowed() {
		if (this.allowedLocal && this.allowedLocal.indexOf('images') !== -1) {
			_.remove(this.allowedLocal, i => i === 'images');
			this.allowedLocal.push(...images);
		}

		this.getAcceptAttr();
	}

	private getAcceptAttr(): void {
		this.acceptString =
			this.allowedLocal || !_.isEmpty(this.allowedLocal)
				? _.map(this.allowedLocal, item => `.${item}`).join(', ')
				: '';
	}

	private checkIfAllowed(file: File) {
		const name = file.name;
		const extension = name.split('.')[name.split('.').length - 1].toLowerCase();
		return _.isNull(this.allowedLocal) ||
			this.allowedLocal.indexOf(extension) !== -1
			? file
			: null;
	}

	public updateFiles(event: any) {
		if (!event || event.target.files.length === 0) return;

		this.resetLocal = false;
		event.preventDefault();
		const target = <HTMLInputElement>event.target;

		if (target.files.length === 0) return;

		const cond = !!(_.isNull(target.files) || target.files.length);
		this.files = _.toArray(target.files);

		if (cond) {
			_.forEach(this.files, (file, index) => {
				if (!!this.checkIfAllowed(file)) {
					this.readFile(file, index);
				} else {
					this.fileChanges.next(null);
					this.toastr.error(
						this.translate.instant('files.formatNotAllowed', {
							allowed: this.allowedLocal
						}),
						file.name
					);
				}
			});
		} else this.fileChanges.next(null);
	}

	public getMB(bytes: number): number {
		return bytes / 1024 / 1024;
	}

	private async readFile(file: IFile, index: number) {
		const isImage = images.find(ext => file.type === `image/${ext}`);

		if (this.getMB(file.size) > this.maxMb) {
			this.fileChanges.next(null);
			this.toastr.error(
				this.translate.instant('files.sizeTooBig', { size: this.maxMb }),
				file.name
			);
		} else {
			if (isImage) {
				const reader: FileReader = new FileReader();
				const preview$ = observableFromEvent(reader, 'load');

				await preview$.subscribe(preview => {
					file.preview = reader.result;
					this.files[index] = file;
					this.fileChanges.next(this.files);
				});

				reader.readAsDataURL(file);
			} else {
				file.preview = this.sanitizer.bypassSecurityTrustUrl(fileIcon);
				this.files[index] = file;
				this.fileChanges.next(this.files);
			}
		}
	}
}
