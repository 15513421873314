import { Injectable } from '@angular/core';
import { LocalStorageEngine } from './localestorage.engine';
import { StorageEngine } from './storageengine.interface';
import { environment } from '../../../environments/environment';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class AppStorage {
	private db: StorageEngine;

	constructor() {
		this.db = this.getEngine();
	}

	private getEngine() {
		return new LocalStorageEngine(environment.storagePrefix);
	}

	public set(key: string, item: any): void {
		this.db.set(key, item);
	}

	public get(key: string): string {
		return this.db.get(key);
	}

	public delete(key: string): void {
		this.db.delete(key);
	}

	public getAll(): any {
		return this.db.getAll();
	}

	public setAsync(key: string, item: any): Observable<any> {
		return defer(() => of(this.db.set(key, item)));
	}

	public getAsync(key: string) {
		return defer(() => of(this.db.get(key)));
	}

	public deleteAsync(key: string) {
		return defer(() => of(this.db.delete(key)));
	}

	public getAllAsync() {
		return defer(() => of(this.db.getAll()));
	}
}

export const storageProvider = () => new AppStorage();
