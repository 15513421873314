/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NotificationDto {
	/**
	 * List of ids returned from the autocomplete method. Only if target = SINGLE_USER or PROVIDERS_GROUP
	 */
	autocompleteIds?: Array<number>;
	content?: string;
	target?: NotificationDto.TargetEnum;
	title?: string;
}
export namespace NotificationDto {
	export type TargetEnum =
		| 'ALL_PROVIDERS'
		| 'ALL_CLIENTS'
		| 'ALL_NEW_USERS'
		| 'SINGLE_USER'
		| 'SPECIFIC_PROVIDERS_GROUP';
	export const TargetEnum = {
		ALLPROVIDERS: 'ALL_PROVIDERS' as TargetEnum,
		ALLCLIENTS: 'ALL_CLIENTS' as TargetEnum,
		ALLNEWUSERS: 'ALL_NEW_USERS' as TargetEnum,
		SINGLEUSER: 'SINGLE_USER' as TargetEnum,
		SPECIFICPROVIDERSGROUP: 'SPECIFIC_PROVIDERS_GROUP' as TargetEnum
	};
}
