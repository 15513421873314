import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginUtils } from './_services/loginutils/loginutils.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppStorage } from '@services/storage/appstorage.service';
import { LoggerService } from '@services/logger/logger.service';

@Component({
	selector: 'app-root',
	template: `
		<ngx-ui-loader
			[hasProgressBar]="false"
			[fgsType]="'three-bounce'"
		></ngx-ui-loader>
		<ng-progress></ng-progress>
		<router-outlet></router-outlet>
	`
})
export class AppComponent implements OnInit {
	title = 'app';
	appRouter$;

	constructor(
		private router: Router,
		private log: LoggerService,
		private loginUtils: LoginUtils,
		private translate: TranslateService,
		private storage: AppStorage
	) {}

	ngOnInit() {
		this.appRouter$ = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.log.debug(event.url);
			}
		});
		this.log.debug('AppComponent', this, this.loginUtils.user);
		this.checkLang();
	}

	private checkLang() {
		this.translate.addLangs(environment.availableLangs);
		this.translate.setDefaultLang(environment.language);

		this.storage
			.getAsync('lang')
			.subscribe(res => this.loginUtils.useLang(res));
	}
}
