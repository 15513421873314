/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SettingsDto {
	adminReportEmail?: string;
	administrativeFeeRank0: number;
	administrativeFeeRank1: number;
	administrativeFeeRank2: number;
	administrativeFeeRank3: number;
	administrativeFeeRank4: number;
	administrativeFeeRank5: number;
	categoriesLimit: number;
	offersSearchDefaultRadius: number;
	servicesLimit: number;
	spDefaultActive: boolean;
	vatRate: number;
}
