import {
	Directive,
	Input,
	Renderer2,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[noDataPlaceholder]'
})
export class NoDataPlaceholderDirective {
	readonly DEFAULT_PLACEHOLDER = this.translate.instant('common.noData');

	@Input()
	public noDataPlaceholderText: string = this.DEFAULT_PLACEHOLDER;

	@Input()
	set noDataPlaceholder(data: any) {
		const isEmpty =
			data === undefined ||
			data === null ||
			((typeof data === 'string' ||
				(typeof data === 'object' && 'length' in data)) &&
				data.length === 0);
		this.viewContainer.clear();
		const viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
		const elementRef = viewRef.rootNodes[0];
		if (isEmpty) {
			this.renderer.setProperty(
				elementRef,
				'innerHTML',
				this.noDataPlaceholderText
			);
		}
	}

	constructor(
		private translate: TranslateService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private renderer: Renderer2
	) {}
}
