export const environment = {
	version: '1.0.0',
	appName: 'Choresmiths',
	production: true,
	apiUrl: 'https://app-stage.choresmiths.com/choresmiths-admin-api',
	storagePrefix: 'CHSM',
	language: 'en',
	availableLangs: ['en'],
	predefToken: null,
	keepStates: true,
	authorizationHeaderName: 'Authorization',
	storageTokenLocation: 'user.accessToken',
	routeWhiteList: ['/login', '/password/reset', '/403'],
	defaultMaxFileSizeMB: 10, // 10 MB
	logLevel: 'DEBUG'
};
