import { of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AppStorage } from '@services/storage/appstorage.service';
import { LoggerService } from '@services/logger/logger.service';
//
// export interface State {
// 	[key: string]: any;
// }
// export interface StateStore {
// 	[name: string]: State;
// }

@Injectable()
export class StateService {
	key = `${environment.storageTokenLocation}-store`;
	store = {};

	constructor(private storage: AppStorage, private log: LoggerService) {
		this.createTree();
	}

	createTree() {
		this.storage.getAsync(this.key).subscribe(res => {
			this.log.debug('State', res);
		});
	}

	save(loc: string, val) {
		const saveLocal = () => _.set(this.store, loc, val);
		if (environment.keepStates)
			this.storage.setAsync(loc, val).subscribe(res => saveLocal());
		else saveLocal();
	}

	read(loc) {
		const readLocal = () => _.get(this.store, loc);
		return environment.keepStates
			? this.storage.getAsync(loc)
			: observableOf(readLocal());
	}
}
