import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpAuth } from './../httpauth/http-auth.service';

export interface DownloadedFile {
	fileName: string;
	content: Blob;
}

@Injectable({
	providedIn: 'root'
})
export class FileDownloaderService {
	private readonly CONTENT_DISPOSITION_HEADER_KEY = 'content-disposition';

	constructor(
		private http: HttpAuth,
		private loaderService: NgxUiLoaderService
	) {}

	downloadBlob(
		apiUrl: string,
		params?: any,
		useLoader: boolean = true
	): Observable<DownloadedFile> {
		if (useLoader) {
			this.loaderService.start();
		}
		let tz = 'UTC';
		try {
			tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
		} catch (e) {}
		const headers = new HttpHeaders({
			ignoreProgressBar: '',
			'client-timezone': tz
		});
		return this.http
			.getWithOptions(apiUrl, {
				responseType: 'blob',
				observe: 'response',
				headers: headers,
				params: params
			})
			.pipe(
				map((res: HttpResponse<Blob>) => ({
					content: res.body,
					fileName: this.getFileNameFromHeaders(res.headers)
				})),
				catchError((err: any) => {
					if (useLoader) {
						this.loaderService.stop();
					}
					return throwError(err);
				}),
				finalize(() => {
					if (useLoader) {
						this.loaderService.stop();
					}
				})
			);
	}

	private getFileNameFromHeaders(headers: HttpHeaders) {
		const fileNameRegex = /filename="(.*\..*)"/g;
		const contentDisposition = headers.get(this.CONTENT_DISPOSITION_HEADER_KEY);
		const regexResults = fileNameRegex.exec(contentDisposition);
		if (regexResults && regexResults[1]) {
			return regexResults[1];
		}
		return null;
	}
}
