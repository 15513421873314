/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Resource saving data
 */
export interface ResourceSaveDto {
	/**
	 * ID of the resource category this resource should belong to
	 */
	categoryId: number;
	/**
	 * Is this resource featured?
	 */
	featured: boolean;
	/**
	 * Required only for editing
	 */
	id?: number;
	/**
	 * Should the resource have it's thumbnail cleared. Only for editing.
	 */
	resetThumbnail?: boolean;
	/**
	 * Resource title
	 */
	title: string;
	/**
	 * External page/resource url
	 */
	url: string;
}
