/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ServiceTypeDto } from './serviceTypeDto';
import { UserNameDto } from './userNameDto';

export interface JobDto {
	client?: UserNameDto;
	clientRating?: number;
	distance?: number;
	finishDate?: Date;
	id?: number;
	location?: string;
	provider?: UserNameDto;
	providerRating?: number;
	realisationDate?: Date;
	service?: ServiceTypeDto;
	startDate?: Date;
	status?: JobDto.StatusEnum;
	timeToAccept?: number;
}
export namespace JobDto {
	export type StatusEnum =
		| 'OPEN'
		| 'PENDING'
		| 'ACCEPTED'
		| 'CLIENT_REJECTED'
		| 'PROVIDER_REJECTED'
		| 'CLIENT_CANCELLED'
		| 'PROVIDER_CANCELLED'
		| 'CLIENT_DONE'
		| 'PROVIDER_DONE'
		| 'PAID'
		| 'FINISHED'
		| 'REFUND_REQUESTED'
		| 'REFUNDED'
		| 'TRANSFER_TO_CLIENT_IN_PROGRESS'
		| 'TRANSFER_TO_PROVIDER_IN_PROGRESS';
	export const StatusEnum = {
		OPEN: 'OPEN' as StatusEnum,
		PENDING: 'PENDING' as StatusEnum,
		ACCEPTED: 'ACCEPTED' as StatusEnum,
		CLIENTREJECTED: 'CLIENT_REJECTED' as StatusEnum,
		PROVIDERREJECTED: 'PROVIDER_REJECTED' as StatusEnum,
		CLIENTCANCELLED: 'CLIENT_CANCELLED' as StatusEnum,
		PROVIDERCANCELLED: 'PROVIDER_CANCELLED' as StatusEnum,
		CLIENTDONE: 'CLIENT_DONE' as StatusEnum,
		PROVIDERDONE: 'PROVIDER_DONE' as StatusEnum,
		PAID: 'PAID' as StatusEnum,
		FINISHED: 'FINISHED' as StatusEnum,
		REFUNDREQUESTED: 'REFUND_REQUESTED' as StatusEnum,
		REFUNDED: 'REFUNDED' as StatusEnum,
		TRANSFERTOCLIENTINPROGRESS: 'TRANSFER_TO_CLIENT_IN_PROGRESS' as StatusEnum,
		TRANSFERTOPROVIDERINPROGRESS:
			'TRANSFER_TO_PROVIDER_IN_PROGRESS' as StatusEnum
	};
}
