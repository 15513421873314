<div class="sidebar-container d-flex flex-column h-100">
	<div class="brand d-flex justify-content-center align-items-center mt-4">
		<img
			[src]="brandLogo.source"
			class="brand-logo"
			[width]="brandLogo.size.width"
			[height]="brandLogo.size.height"
		/>
		<h3 class="brand-name text-white">{{ 'general.brandName' | translate }}</h3>
	</div>

	<ul class="list-group list-group-flush main-nav mb-5">
		<ng-template #sidebarRecursiveMenu let-sidebarMenus>
			<li *ngFor="let menu of sidebarMenus" [class.parentLink]="menu.children">
				<a
					(click)="toggleExpand(menu)"
					[routerLink]="menu.path"
					[routerLinkActive]="['link-active']"
					class="d-flex align-items-center"
				>
					<img
						*ngIf="menu.icon"
						[src]="'./../../../assets/images/icons/' + menu.icon"
						class="menu-item-icon"
					/>
					{{ 'menu.' + menu.slug | translate }}
				</a>
				<ul *ngIf="menu.children && menu.opened">
					<ng-container
						*ngTemplateOutlet="
							sidebarRecursiveMenu;
							context: { $implicit: menu.children }
						"
					></ng-container>
				</ul>
			</li>
		</ng-template>

		<ng-container
			*ngTemplateOutlet="
				sidebarRecursiveMenu;
				context: { $implicit: sidebarMenus }
			"
		></ng-container>
	</ul>

	<div class="actions-container mt-auto mb-1">
		<a
			class="d-flex align-items-center"
			[ngClass]="{ 'link-active': isSettingsScreen }"
			[routerLink]="['/settings']"
			(click)="closeAllMenus()"
			*hasRole="UserRole.SUPERADMIN"
		>
			<img
				src="./../../../assets/images/icons/build.svg"
				width="24"
				height="24"
				class="menu-item-icon"
			/>{{ 'general.settings' | translate }}
		</a>
		<a class="d-flex align-items-center" (click)="signOut()">
			<img
				src="./../../../assets/images/icons/power-settings-new.svg"
				width="24"
				height="24"
				class="menu-item-icon"
			/>{{ 'login.signOut' | translate }}
		</a>
	</div>
	<footer class="my-2">
		<small class="text-center text-muted d-block"
			>{{ 'general.copyrights' | translate }}
		</small>
		<small class="text-center d-block"
			>{{ 'general.version' | translate }} {{ version.version }}
			<span *ngIf="!isProduction && version.hash && version.buildDate"
				>[{{ version.hash }}] {{ version.buildDate | date: 'long' }}</span
			></small
		>
	</footer>
</div>
