<div class="choresmiths-card-modal">
	<div class="d-flex justify-content-between align-items-center">
		<h5 class="my-0">{{ title }}</h5>
		<button class="btn btn-link p-0" (click)="activeModal.dismiss('cancel')">
			<img src="./../../../assets/images/icons/close-gray.svg" />
		</button>
	</div>
	<div *ngIf="user" class="user-details-container">
		<div class="row mx-0">
			<div class="col-auto px-0">
				<img
					class="avatar"
					[src]="user.photoUrl"
					alt="{{ 'users.avatarAltText' | translate }}"
				/>
			</div>
			<div class="col px-0">
				<h5 class="my-2">{{ 'users.details' | translate }}</h5>

				<div class="d-flex">
					<label class="col-6 px-0">{{ 'users.name' | translate }}:</label>
					<div class="col-6 px-0" *noDataPlaceholder="user.fullName">
						{{ user.fullName }}
					</div>
				</div>
				<div class="d-flex">
					<label class="col-6 px-0">{{ 'users.email' | translate }}:</label>
					<div class="col-6 px-0">{{ user.email }}</div>
				</div>
				<div class="d-flex">
					<label class="col-6 px-0">{{ 'users.phone' | translate }}:</label>
					<div class="col-6 px-0">{{ user.phoneNumber }}</div>
				</div>
				<ng-container *ngIf="mode === 'provider'">
					<div class="d-flex">
						<label class="col-6 px-0"
							>{{ 'users.homeLocation' | translate }}:</label
						>
						<div class="col-6 px-0" *noDataPlaceholder="user.homeLocation">
							{{ user.homeLocation }}
						</div>
					</div>
					<div class="d-flex">
						<label class="col-6 px-0"
							>{{ 'users.companyName' | translate }}:</label
						>
						<div class="col-6 px-0" *noDataPlaceholder="user.companyName">
							{{ user.companyName }}
						</div>
					</div>
					<div class="d-flex">
						<label class="col-6 px-0"
							>{{ 'users.socialMediaLink' | translate }}:</label
						>
						<div class="col-6 px-0" *noDataPlaceholder="user.socialMediaLink">
							<a [href]="user.socialMediaLink" target="_blank">
								<button class="btn btn-link p-0" style="text-align: left">
									{{ user.socialMediaLink }}
								</button>
							</a>
						</div>
					</div>
				</ng-container>

				<div class="d-flex">
					<label class="col-6 px-0">{{ 'jobs.rating' | translate }}:</label>
					<div class="col-6 px-0" *noDataPlaceholder="user.rating">
						{{ user.rating | number: '1.1-1' }} /
						{{ maxRating | number: '1.1-1' }}
					</div>
				</div>
				<div class="d-flex" *ngIf="mode === 'provider'">
					<label class="col-6 px-0"
						>{{ 'users.providers.categories' | translate }}:</label
					>
					<div class="col-6 px-0" *noDataPlaceholder="user.categories">
						{{ user.categories | join: ', ' }}
					</div>
				</div>
				<div class="d-flex">
					<label class="col-6 px-0">{{ 'users.openJobs' | translate }}:</label>
					<div class="col-6 px-0">{{ user.openJobsCount }}</div>
				</div>
				<div class="d-flex">
					<label class="col-6 px-0"
						>{{ 'users.confirmedJobs' | translate }}:</label
					>
					<div class="col-6 px-0">{{ user.confirmedJobsCount }}</div>
				</div>
				<div class="d-flex">
					<label class="col-6 px-0"
						>{{ 'users.closedJobs' | translate }}:</label
					>
					<div class="col-6 px-0">{{ user.closedJobsCount }}</div>
				</div>
				<div class="d-flex">
					<label class="col-6 px-0"
						>{{ 'users.jobsCompletedPercentage' | translate }}:</label
					>
					<div class="col-6 px-0">
						({{ user.completedPercent | number: '2.2-2' }}%)
					</div>
				</div>
				<ng-container *ngIf="mode === 'provider'">
					<div class="d-flex">
						<label class="col-6 px-0"
							>{{ 'users.description' | translate }}:</label
						>
						<div
							class="col-6 px-0"
							*noDataPlaceholder="user.description"
							class="description"
						>
							{{ user.description }}
						</div>
					</div>
					<div class="status-transitions">
						<h5 class="my-2">
							{{ 'users.providers.manageStatus' | translate }}
						</h5>

						<div class="d-flex mb-2">
							<div class="col-6 px-0">
								<label>{{ 'jobsPayments.currentStatus' | translate }}:</label>
							</div>
							<div
								class="col-6 px-0 onboarding-status"
								[class.action-needed]="user.isActionNeeded"
							>
								{{
									'OnboardingStatusEnum.' + user.onboardingStatus | translate
								}}
							</div>
						</div>
						<div class="d-flex">
							<ng-container *ngIf="user.canChangeOnboardingStatus">
								<label class="col-6 px-0">
									{{ 'jobsPayments.newStatus' | translate }}:
								</label>
								<div class="col-6 px-0">
									<ng-select
										[(ngModel)]="selectedOnboardingStatus"
										[items]="user.allowedOnboardingStatusTransitions"
										[clearable]="false"
										[searchable]="false"
										class="choresmiths-ng-select"
										bindValue="value"
										bindLabel="label"
									>
									</ng-select>
								</div>
							</ng-container>
							<div class="col-6 px-0" *ngIf="!user.canChangeOnboardingStatus">
								{{ 'jobsPayments.statusUnchangeable' | translate }}
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="d-flex justify-content-end modal-btn-group">
		<ng-container *ngIf="!user.deleted">
			<button
				class="btn btn-success upper"
				(click)="saveOnboardingStatus()"
				*ngIf="user.canChangeOnboardingStatus"
				[disabled]="
					submitPending ||
					!selectedOnboardingStatus ||
					selectedOnboardingStatus === user.onboardingStatus
				"
			>
				{{ 'common.save' | translate }}
			</button>
			<button
				class="btn btn-danger upper"
				*hasRole="'SUPERADMIN'"
				(click)="deleteUser()"
			>
				{{ 'common.delete' | translate }}
			</button>
			<button class="btn btn-warning upper" (click)="toggleUserBlocked()">
				{{ 'common.' + (user.blocked ? 'unblock' : 'block') | translate }}
			</button>
		</ng-container>
		<button
			class="btn btn-primary upper"
			(click)="activeModal.dismiss('cancel')"
		>
			{{ 'common.close' | translate }}
		</button>
	</div>
</div>
