import { FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export namespace CustomValidators {
	export function notBlank(minLength: number): ValidatorFn {
		return (control: FormControl) => {
			if (control.value) {
				const text = control.value as string;
				if (text.length >= minLength && !text.trim().length) {
					return {
						notBlank: true
					};
				}
			}
			return null;
		};
	}

	export function resourceUrlPattern(control: FormControl): ValidationErrors {
		const pattern = /https?:\/\/.*/;
		if (control.value) {
			const text = control.value as string;
			if (!pattern.test(text)) {
				return {
					resourceUrlPattern: true
				};
			}
			return null;
		}
		return null;
	}

	export function isInteger(control: FormControl): ValidationErrors {
		const pattern = /^[0-9]*$/;
		if (control.value == null) {
			return null;
		}

		const text: string = control.value;
		if (!pattern.test(text)) {
			return {
				isInteger: true
			};
		}
		return null;
	}
}
