import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { HttpAuth } from '../_services/httpauth/http-auth.service';
import { LoginUtils } from '../_services/loginutils/loginutils.service';
import { UserResponseDto } from '@models/userResponseDto';

@Injectable()
export class ReloadUserResolver implements Resolve<any> {
	constructor(private http: HttpAuth, private loginutils: LoginUtils) {}

	resolve(route: ActivatedRouteSnapshot) {
		if (this.loginutils.isLogged) this.reloadUser();
		else this.loginutils.user = null;
		return true;
	}

	async reloadUser() {
		await this.http.get<UserResponseDto>('/user/profile').subscribe(
			res => {
				this.loginutils.user = res;
			},
			err => {
				this.loginutils.user = null;
			}
		);
		return true;
	}
}
