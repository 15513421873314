/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserDetailsDto {
	allowedOnboardingStatusTransitions?: Array<UserDetailsDto.AllowedOnboardingStatusTransitionsEnum>;
	blocked?: boolean;
	categories?: Array<string>;
	closedJobsCount?: number;
	companyName?: string;
	completedPercent?: number;
	confirmedJobsCount?: number;
	deleted?: boolean;
	description?: string;
	email?: string;
	fullName?: string;
	homeLocation?: string;
	id?: number;
	onboardingStatus?: UserDetailsDto.OnboardingStatusEnum;
	openJobsCount?: number;
	phoneNumber?: string;
	photoUrl?: string;
	rating?: number;
	role?: UserDetailsDto.RoleEnum;
	socialMediaLink?: string;
	spAccountActivated?: boolean;
}
export namespace UserDetailsDto {
	export type AllowedOnboardingStatusTransitionsEnum =
		| 'REGISTRATION'
		| 'SCREENING_YET_TO_START'
		| 'SCREENING_IN_PROGRESS'
		| 'SCREENING_REJECTED'
		| 'OFFLINE_ONBOARDING_YET_TO_START'
		| 'OFFLINE_ONBOARDING_IN_PROGRESS'
		| 'OFFLINE_ONBOARDING_REJECTED'
		| 'OFFLINE_ONBOARDING_ACCEPTED'
		| 'ONLINE_ONBOARDING_COMPLETED';
	export const AllowedOnboardingStatusTransitionsEnum = {
		REGISTRATION: 'REGISTRATION' as AllowedOnboardingStatusTransitionsEnum,
		SCREENINGYETTOSTART:
			'SCREENING_YET_TO_START' as AllowedOnboardingStatusTransitionsEnum,
		SCREENINGINPROGRESS:
			'SCREENING_IN_PROGRESS' as AllowedOnboardingStatusTransitionsEnum,
		SCREENINGREJECTED:
			'SCREENING_REJECTED' as AllowedOnboardingStatusTransitionsEnum,
		OFFLINEONBOARDINGYETTOSTART:
			'OFFLINE_ONBOARDING_YET_TO_START' as AllowedOnboardingStatusTransitionsEnum,
		OFFLINEONBOARDINGINPROGRESS:
			'OFFLINE_ONBOARDING_IN_PROGRESS' as AllowedOnboardingStatusTransitionsEnum,
		OFFLINEONBOARDINGREJECTED:
			'OFFLINE_ONBOARDING_REJECTED' as AllowedOnboardingStatusTransitionsEnum,
		OFFLINEONBOARDINGACCEPTED:
			'OFFLINE_ONBOARDING_ACCEPTED' as AllowedOnboardingStatusTransitionsEnum,
		ONLINEONBOARDINGCOMPLETED:
			'ONLINE_ONBOARDING_COMPLETED' as AllowedOnboardingStatusTransitionsEnum
	};
	export type OnboardingStatusEnum =
		| 'REGISTRATION'
		| 'SCREENING_YET_TO_START'
		| 'SCREENING_IN_PROGRESS'
		| 'SCREENING_REJECTED'
		| 'OFFLINE_ONBOARDING_YET_TO_START'
		| 'OFFLINE_ONBOARDING_IN_PROGRESS'
		| 'OFFLINE_ONBOARDING_REJECTED'
		| 'OFFLINE_ONBOARDING_ACCEPTED'
		| 'ONLINE_ONBOARDING_COMPLETED';
	export const OnboardingStatusEnum = {
		REGISTRATION: 'REGISTRATION' as OnboardingStatusEnum,
		SCREENINGYETTOSTART: 'SCREENING_YET_TO_START' as OnboardingStatusEnum,
		SCREENINGINPROGRESS: 'SCREENING_IN_PROGRESS' as OnboardingStatusEnum,
		SCREENINGREJECTED: 'SCREENING_REJECTED' as OnboardingStatusEnum,
		OFFLINEONBOARDINGYETTOSTART:
			'OFFLINE_ONBOARDING_YET_TO_START' as OnboardingStatusEnum,
		OFFLINEONBOARDINGINPROGRESS:
			'OFFLINE_ONBOARDING_IN_PROGRESS' as OnboardingStatusEnum,
		OFFLINEONBOARDINGREJECTED:
			'OFFLINE_ONBOARDING_REJECTED' as OnboardingStatusEnum,
		OFFLINEONBOARDINGACCEPTED:
			'OFFLINE_ONBOARDING_ACCEPTED' as OnboardingStatusEnum,
		ONLINEONBOARDINGCOMPLETED:
			'ONLINE_ONBOARDING_COMPLETED' as OnboardingStatusEnum
	};
	export type RoleEnum =
		| 'SYSTEM'
		| 'ROOT'
		| 'SUPERADMIN'
		| 'ADMIN'
		| 'PROVIDER'
		| 'CLIENT'
		| 'PROVIDER_REGISTRATION';
	export const RoleEnum = {
		SYSTEM: 'SYSTEM' as RoleEnum,
		ROOT: 'ROOT' as RoleEnum,
		SUPERADMIN: 'SUPERADMIN' as RoleEnum,
		ADMIN: 'ADMIN' as RoleEnum,
		PROVIDER: 'PROVIDER' as RoleEnum,
		CLIENT: 'CLIENT' as RoleEnum,
		PROVIDERREGISTRATION: 'PROVIDER_REGISTRATION' as RoleEnum
	};
}
