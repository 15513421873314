import { UserDetailsDto } from '@models/userDetailsDto';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ProvidersService } from '@routes/users/providers/providers.service';
import { UserDetails, UsersService } from '@routes/users/users.service';
import { ModalService } from '@services/modal/modal.service';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { ErrorsService } from '@services/errors/errors.service';
import { ModalResult } from '@services/modal/modal.service';
import { Utils } from '@services/utils/utils';
import { UserDetailsMode } from './../../../jobs/jobs-list/jobs-list.component';

export enum UserDetailsModalAction {
	Block = 1,
	Delete = 2,
	Verify = 3,
	OnboardingChange = 4
}

@Component({
	selector: 'app-user-details-modal',
	templateUrl: './user-details-modal.component.html',
	styleUrls: ['./user-details-modal.component.scss']
})
export class UserDetailsModalComponent {
	public readonly maxRating = Utils.MaxUserRating;
	// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
	public readonly ModalAction = UserDetailsModalAction;

	public title: string;
	private userDetailsMode: UserDetailsMode;
	public set mode(value: UserDetailsMode) {
		this.userDetailsMode = value;
		this.title = this.translate.instant(`users.${value}s.details.title`);
	}
	public get mode() {
		return this.userDetailsMode;
	}
	public user: UserDetails;

	public selectedOnboardingStatus: UserDetailsDto.OnboardingStatusEnum;
	public submitPending = false;

	constructor(
		public activeModal: NgbActiveModal,
		private usersService: UsersService,
		private providersService: ProvidersService,
		private modalService: ModalService,
		private errorsService: ErrorsService,
		private translate: TranslateService,
		private toastrService: ToastrService
	) {}

	deleteUser() {
		const confirmationResult = this.modalService.showConfirmationDialog(
			{ title: 'modals.users.delete.single.title', shouldTranslate: true },
			true
		).result;
		confirmationResult
			.pipe(
				concatMap((res: ModalResult<void>) => {
					if (res.isOk()) {
						return this.usersService.deleteUser(this.user.id);
					}
					return EMPTY;
				})
			)
			.subscribe(
				() => this.activeModal.close(UserDetailsModalAction.Delete),
				err => this.errorsService.handleGlobalError(err)
			);
	}

	toggleUserBlocked() {
		const method = this.usersService[
			this.user.blocked ? 'bulkUnblockUsers' : 'bulkBlockUsers'
		].bind(this.usersService);
		const confirmationResult = this.modalService.showConfirmationDialog(
			{
				title: `modals.users.${
					this.user.blocked ? 'unblock' : 'block'
				}.single.title`,
				shouldTranslate: true
			},
			false
		).result;
		confirmationResult
			.pipe(
				concatMap((res: ModalResult<void>) => {
					if (res.isOk()) {
						return method([this.user.id]);
					}
					return EMPTY;
				})
			)
			.subscribe(
				() => {
					const roleFragment =
						this.user.role === 'PROVIDER' ? 'providers' : 'clients';
					this.toastrService.success(
						this.translate.instant(
							`users.${roleFragment}.messages.${
								!this.user.blocked ? 'blocked' : 'unblocked'
							}.single`
						),
						this.translate.instant('common.success')
					);
					this.activeModal.close(UserDetailsModalAction.Block);
				},
				err => this.errorsService.handleGlobalError(err)
			);
	}

	verifyUser() {
		const confirmationResult = this.modalService.showConfirmationDialog(
			{
				title: this.translate.instant(`modals.users.verify.title`)
			},
			false
		);

		confirmationResult.result
			.pipe(
				concatMap((res: ModalResult<void>) => {
					if (res.isOk()) {
						return this.providersService.verifyProvider(this.user.id);
					}
					return EMPTY;
				})
			)
			.subscribe(
				() => this.activeModal.close(UserDetailsModalAction.Verify),
				err => this.errorsService.handleGlobalError(err)
			);
	}

	saveOnboardingStatus() {
		const confirmationResult = this.modalService.showConfirmationDialog(
			{
				title: this.translate.instant(
					`modals.providers.update_onboarding_status.title`
				)
			},
			false
		);

		confirmationResult.result
			.pipe(
				concatMap((res: ModalResult<void>) => {
					if (res.isOk()) {
						this.submitPending = true;
						return this.providersService.updateOnboardingStatus(this.user.id, {
							value: this.selectedOnboardingStatus
						});
					}
					return EMPTY;
				})
			)
			.subscribe(
				() => {
					this.submitPending = false;
					this.toastrService.success(
						this.translate.instant(
							'users.providers.messages.onboardingStatusChanged.title'
						),
						this.translate.instant('common.success')
					);
					this.activeModal.close(UserDetailsModalAction.OnboardingChange);
				},
				err => {
					this.errorsService.handleGlobalError(err);
					this.submitPending = false;
				}
			);
	}
}
