/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BankAccountAdminDto {
	accountNumber?: string;
	country?: BankAccountAdminDto.CountryEnum;
	createdAt?: Date;
	currency?: BankAccountAdminDto.CurrencyEnum;
	id?: number;
	name?: string;
	providerId?: number;
	verificationNumber?: string;
}
export namespace BankAccountAdminDto {
	export type CountryEnum = 'NG' | 'KE';
	export const CountryEnum = {
		NG: 'NG' as CountryEnum,
		KE: 'KE' as CountryEnum
	};
	export type CurrencyEnum = 'NGN' | 'USD' | 'KES' | 'ZAR';
	export const CurrencyEnum = {
		NGN: 'NGN' as CurrencyEnum,
		USD: 'USD' as CurrencyEnum,
		KES: 'KES' as CurrencyEnum,
		ZAR: 'ZAR' as CurrencyEnum
	};
}
