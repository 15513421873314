import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'capitalize',
	pure: true
})
export class CapitalizePipe implements PipeTransform {
	transform(value: string | Array<string>): string | Array<string> {
		const capitalizeFn = (text: string) =>
			text[0].toUpperCase() + text.substr(1);
		if (typeof value === 'object' && 'length' in value) {
			return value.map((element: string) => capitalizeFn(element));
		} else if (typeof value === 'string') {
			return capitalizeFn(value);
		}
		return value;
	}
}
