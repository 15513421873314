import { timer as observableTimer } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { LoggerService } from '@services/logger/logger.service';

@Injectable()
export class ExampleResolver implements Resolve<any> {
	constructor(private log: LoggerService) {}

	async resolve(route: ActivatedRouteSnapshot) {
		const t = 300;
		await observableTimer(t).toPromise();

		this.log.log(`resolver example, delay: ${t}`);
		return true;
	}
}
