import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'app-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
	@Input()
	public placeholder = 'Search';

	@Input()
	public debounceTimeMs;

	@Input()
	public showClearButton = true;

	@Output()
	search: EventEmitter<string> = new EventEmitter();

	private searchSubject$: Subject<string> = new Subject();

	searchModel: string;

	constructor() {}

	ngOnInit() {
		const search$ = this.debounceTimeMs
			? this.searchSubject$.pipe(debounceTime(this.debounceTimeMs))
			: this.searchSubject$;

		search$.subscribe((searchText: string) => {
			this.search.emit(searchText);
		});
	}

	onSearch() {
		this.searchSubject$.next(this.searchModel);
	}

	clear() {
		this.searchModel = '';
		this.searchSubject$.next(this.searchModel);
	}
}
