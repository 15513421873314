import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-terms-of-use',
	templateUrl: './terms-of-use.component.html',
	styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
	ngOnInit() {
		window.location.href = '/terms-of-use.html';
	}
}
