import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface DropdownItem {
	value: any;
	label: string;
}

@Component({
	selector: 'app-dropdown-field',
	templateUrl: './dropdown-field.component.html',
	styleUrls: ['./dropdown-field.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DropdownFieldComponent),
			multi: true
		}
	]
})
export class DropdownFieldComponent implements ControlValueAccessor {
	private localItems: Array<DropdownItem>;
	private expanded = false;

	@Input()
	public set items(value: Array<DropdownItem>) {
		this.localItems = value;
		if (!this.selectedItem && !!this.selectDefault) {
			this.selectItem(value[0]);
			// this.writeValue(value[0]);
		}
	}

	public get items() {
		return this.localItems;
	}

	@Input()
	public selectDefault: boolean;

	@Input()
	public placeholder: string;

	@Input()
	public outputValueMapper: (value: any) => any;

	@Input()
	public inputValueMapper: (value: any) => any;

	@Input()
	public disabled: boolean;

	@Input()
	public scrollableItems = true;

	/**
	 * Max height for the items container to display without overflowing / scrollbar
	 */
	@Input()
	public scrollTresholdHeight = 300; // px

	@Output()
	public selected: EventEmitter<DropdownItem> = new EventEmitter();

	@Output()
	public focusLost: EventEmitter<void> = new EventEmitter();

	public selectedItem: DropdownItem;

	propagateChange = (_: any) => {};
	onTouched = () => {};

	writeValue(value: DropdownItem | any): void {
		if (value) {
			if (typeof value === 'object') {
				this.selectedItem = value;
				this.propagateChange(
					!!this.outputValueMapper ? this.outputValueMapper(value.value) : value
				);
			} else {
				this.selectedItem = !!this.inputValueMapper
					? this.inputValueMapper(value)
					: value;
				this.propagateChange(this.selectedItem);
			}
		} else {
			this.selectedItem = null;
			this.propagateChange(this.selectedItem);
		}
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	selectItem(item: DropdownItem) {
		this.writeValue(item);
		this.selected.emit(item);
	}

	expand() {
		this.onTouched();
		this.expanded = !this.expanded;
		if (!this.expanded) {
			this.focusLost.emit();
		}
	}
}
