import { Component, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { ConfirmModalComponent } from './../../_components/confirm-modal/confirm-modal.component';
import { InfoModalComponent } from '@components/info-modal/info-modal.component';

export enum ModalResultType {
	Dismiss = -1,
	Ok = 0
}
export class ModalResult<T> {
	constructor(public resultType: any) {}
	static observable<T>(resultType: any) {
		return of(new ModalResult<T>(resultType));
	}
	isOk() {
		return this.resultType === ModalResultType.Ok;
	}
	isDismiss() {
		return this.resultType === ModalResultType.Dismiss;
	}
}

export interface ModalData<T> {
	readonly componentInstance: T;
	readonly result: Observable<any>;
	close: (result?: any) => void;
	dismiss: (reason?: any) => void;
}

@Injectable()
export class ModalService {
	public readonly baseWindowClasses: string =
		'transparent d-flex align-items-center justify-content-center ';

	constructor(
		private modalService: NgbModal,
		private translate: TranslateService
	) {}

	open<T>(content: Component | any, options?: NgbModalOptions): ModalData<T> {
		options = {
			...options,
			keyboard: false,
			backdrop: 'static'
		};
		const modalValue = this.modalService.open(content, options);
		const resultObservable = from(modalValue.result).pipe(
			concatMap((res: any) => {
				return ModalResult.observable(res === 'ok' ? ModalResultType.Ok : res);
			}),
			// Catch modal cancelation / promise rejection
			catchError((err: any) => {
				if (err === 'cancel') {
					return ModalResult.observable(ModalResultType.Dismiss);
				}
				throwError(err);
			})
		);

		const modalResult: ModalData<T> = {
			componentInstance: modalValue.componentInstance,
			result: resultObservable,
			close: modalValue.close,
			dismiss: modalValue.dismiss
		};
		return modalResult;
	}

	showConfirmationDialog(
		{
			title,
			description,
			shouldTranslate
		}: { title: string; description?: string; shouldTranslate?: boolean },
		permanentAction?: boolean
	): ModalData<ConfirmModalComponent> {
		const dialogRef: ModalData<ConfirmModalComponent> = this.open(
			ConfirmModalComponent,
			{
				windowClass: this.baseWindowClasses.concat('confirmation-modal')
			}
		);

		dialogRef.componentInstance.title = shouldTranslate
			? this.translate.instant(title)
			: title;
		if (description && !permanentAction) {
			dialogRef.componentInstance.description = shouldTranslate
				? this.translate.instant(description)
				: description;
		} else if (permanentAction) {
			dialogRef.componentInstance.description = this.translate.instant(
				'modals.common.permanentAction'
			);
		}

		return dialogRef;
	}

	showInfoDialog({
		title,
		description,
		shouldTranslate
	}: {
		title: string;
		description?: string;
		shouldTranslate?: boolean;
	}): ModalData<InfoModalComponent> {
		const dialogRef: ModalData<InfoModalComponent> = this.open(
			InfoModalComponent,
			{
				windowClass: this.baseWindowClasses.concat('info-modal')
			}
		);

		dialogRef.componentInstance.title = shouldTranslate
			? this.translate.instant(title)
			: title;
		dialogRef.componentInstance.description = shouldTranslate
			? this.translate.instant(description)
			: description;

		return dialogRef;
	}

	dismisAll() {
		this.modalService.dismissAll();
	}
	hasOpenModals() {
		this.modalService.hasOpenModals();
	}
}
