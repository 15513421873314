import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export enum LogLevel {
	DEBUG,
	LOG,
	INFO,
	WARN,
	ERROR
}

@Injectable({ providedIn: 'root' })
export class LoggerService {
	private logLevel: LogLevel = null;

	constructor() {
		this.logLevel = LogLevel[environment.logLevel];
		if (this.logLevel != null) {
			console.debug(`Log level: ${environment.logLevel}`);
		} else {
			console.warn(
				`Invalid log level in environment.logLevel: ${environment.logLevel}`
			);
		}
	}

	public debug(message: string, ...additional) {
		this._log(LogLevel.DEBUG, message, additional);
	}

	public info(message: string, ...additional) {
		this._log(LogLevel.INFO, message, additional);
	}

	public log(message: string, ...additional) {
		this._log(LogLevel.LOG, message, additional);
	}

	public warn(message: string, ...additional) {
		this._log(LogLevel.WARN, message, additional);
	}

	public error(message: string, ...additional) {
		this._log(LogLevel.ERROR, message, additional);
	}

	private _log(level: LogLevel, message: string, additional: any[]) {
		if (level < this.logLevel) {
			return;
		}
		const levelName = LogLevel[level];
		message = `%c[${levelName}]: ` + message;
		switch (level) {
			case LogLevel.DEBUG:
				console.debug(
					message,
					'color:#c0c0c0; background:transparent',
					...additional
				);
				break;
			case LogLevel.LOG:
				console.log(
					message,
					'color:#a6caed; background:transparent',
					...additional
				);
				break;
			case LogLevel.INFO:
				console.info(
					message,
					'color:#a6caed; background:transparent',
					...additional
				);
				break;
			case LogLevel.WARN:
				console.warn(
					message,
					'color:darkorange; background:transparent',
					...additional
				);
				break;
			case LogLevel.ERROR:
				console.error(
					message,
					'color:red; background:transparent',
					...additional
				);
				break;
			default:
		}
	}
}
