/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * User
 */
export interface UserResponseDto {
	avatarUrl?: string;
	averageRating: number;
	companyName: string;
	description: string;
	email: string;
	facebookConnected: boolean;
	fullName: string;
	id: number;
	/**
	 * Last user interaction with api
	 */
	lastActivityAt: Date;
	locationCity: string;
	locationLatitude: number;
	locationLongitude: number;
	locationStreet: string;
	locationStreetNumber: string;
	memberSince: number;
	onboardingStatus: UserResponseDto.OnboardingStatusEnum;
	passwordEnabled: boolean;
	phoneNumber: string;
	remoteLocationLatitude: number;
	remoteLocationLongitude: number;
	remoteLocationUpdatedAt: number;
	role: UserResponseDto.RoleEnum;
	socialMediaLink?: string;
	spAccountActivated: boolean;
	status: UserResponseDto.StatusEnum;
	uploaded: boolean;
}
export namespace UserResponseDto {
	export type OnboardingStatusEnum =
		| 'REGISTRATION'
		| 'SCREENING_YET_TO_START'
		| 'SCREENING_IN_PROGRESS'
		| 'SCREENING_REJECTED'
		| 'OFFLINE_ONBOARDING_YET_TO_START'
		| 'OFFLINE_ONBOARDING_IN_PROGRESS'
		| 'OFFLINE_ONBOARDING_REJECTED'
		| 'OFFLINE_ONBOARDING_ACCEPTED'
		| 'ONLINE_ONBOARDING_COMPLETED';
	export const OnboardingStatusEnum = {
		REGISTRATION: 'REGISTRATION' as OnboardingStatusEnum,
		SCREENINGYETTOSTART: 'SCREENING_YET_TO_START' as OnboardingStatusEnum,
		SCREENINGINPROGRESS: 'SCREENING_IN_PROGRESS' as OnboardingStatusEnum,
		SCREENINGREJECTED: 'SCREENING_REJECTED' as OnboardingStatusEnum,
		OFFLINEONBOARDINGYETTOSTART:
			'OFFLINE_ONBOARDING_YET_TO_START' as OnboardingStatusEnum,
		OFFLINEONBOARDINGINPROGRESS:
			'OFFLINE_ONBOARDING_IN_PROGRESS' as OnboardingStatusEnum,
		OFFLINEONBOARDINGREJECTED:
			'OFFLINE_ONBOARDING_REJECTED' as OnboardingStatusEnum,
		OFFLINEONBOARDINGACCEPTED:
			'OFFLINE_ONBOARDING_ACCEPTED' as OnboardingStatusEnum,
		ONLINEONBOARDINGCOMPLETED:
			'ONLINE_ONBOARDING_COMPLETED' as OnboardingStatusEnum
	};
	export type RoleEnum =
		| 'SYSTEM'
		| 'ROOT'
		| 'SUPERADMIN'
		| 'ADMIN'
		| 'PROVIDER'
		| 'CLIENT'
		| 'PROVIDER_REGISTRATION';
	export const RoleEnum = {
		SYSTEM: 'SYSTEM' as RoleEnum,
		ROOT: 'ROOT' as RoleEnum,
		SUPERADMIN: 'SUPERADMIN' as RoleEnum,
		ADMIN: 'ADMIN' as RoleEnum,
		PROVIDER: 'PROVIDER' as RoleEnum,
		CLIENT: 'CLIENT' as RoleEnum,
		PROVIDERREGISTRATION: 'PROVIDER_REGISTRATION' as RoleEnum
	};
	export type StatusEnum = 'HOME' | 'REMOTE' | 'NOT_AVAILABLE';
	export const StatusEnum = {
		HOME: 'HOME' as StatusEnum,
		REMOTE: 'REMOTE' as StatusEnum,
		NOTAVAILABLE: 'NOT_AVAILABLE' as StatusEnum
	};
}
