/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface URI {
	absolute?: boolean;
	authority?: string;
	fragment?: string;
	host?: string;
	opaque?: boolean;
	path?: string;
	port?: number;
	query?: string;
	rawAuthority?: string;
	rawFragment?: string;
	rawPath?: string;
	rawQuery?: string;
	rawSchemeSpecificPart?: string;
	rawUserInfo?: string;
	scheme?: string;
	schemeSpecificPart?: string;
	userInfo?: string;
}
