/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface JobStatusAdminDto {
	status: JobStatusAdminDto.StatusEnum;
}
export namespace JobStatusAdminDto {
	export type StatusEnum =
		| 'CLIENT_CANCELLED'
		| 'PROVIDER_CANCELLED'
		| 'TRANSFER_TO_PROVIDER_IN_PROGRESS'
		| 'FINISHED'
		| 'REFUND_REQUESTED'
		| 'TRANSFER_TO_CLIENT_IN_PROGRESS'
		| 'REFUNDED';
	export const StatusEnum = {
		CLIENTCANCELLED: 'CLIENT_CANCELLED' as StatusEnum,
		PROVIDERCANCELLED: 'PROVIDER_CANCELLED' as StatusEnum,
		TRANSFERTOPROVIDERINPROGRESS:
			'TRANSFER_TO_PROVIDER_IN_PROGRESS' as StatusEnum,
		FINISHED: 'FINISHED' as StatusEnum,
		REFUNDREQUESTED: 'REFUND_REQUESTED' as StatusEnum,
		TRANSFERTOCLIENTINPROGRESS: 'TRANSFER_TO_CLIENT_IN_PROGRESS' as StatusEnum,
		REFUNDED: 'REFUNDED' as StatusEnum
	};
}
