import { CustomValidators } from '@services/utils/custom-validators';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsDto } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorsService } from '@services/errors/errors.service';
import { SettingsManagementService } from './settings-management.service';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
	private readonly administrativeFeeRanksNumber = 6;
	private readonly administrativeFeeRankKey = 'administrativeFeeRank';

	public settingsForm: FormGroup;
	public settings: SettingsDto;
	public formErrors: any = null;
	public globalError: any = null;
	public submitPending = false;
	public administrativeFeeControls: { key: string; [k: string]: unknown }[];

	constructor(
		private fb: FormBuilder,
		private settingsManagement: SettingsManagementService,
		private cd: ChangeDetectorRef,
		private translate: TranslateService,
		private errorsService: ErrorsService,
		private toastrService: ToastrService
	) {}

	ngOnInit() {
		this.settingsManagement.getSettings().subscribe(
			(res: SettingsDto) => {
				this.settings = res;
				this.buildForm();
				this.cd.detectChanges();
			},
			err => {
				this.buildForm();
			}
		);
	}

	submitForm() {
		this.formErrors = null;
		this.globalError = null;
		this.submitPending = true;
		const data: SettingsDto = this.settingsForm.value as SettingsDto;
		this.settingsManagement.saveSettings(data).subscribe(
			res => {
				this.toastrService.success(
					this.translate.instant('settings.messages.save.success')
				);
				this.submitPending = false;
			},
			err => {
				this.submitPending = false;
				if (err.error) {
					if (err.error.fieldErrors) {
						this.formErrors = err.error.fieldErrors;
					} else {
						this.errorsService.handleGlobalError(err);
					}
				}
			}
		);
	}

	private buildForm() {
		this.administrativeFeeControls = this.buildAdministrativeRankControls();

		const feeControls = this.administrativeFeeControls.reduce(
			(acc, curr) => ((acc[curr.key] = curr[curr.key]), acc),
			{}
		);

		this.settingsForm = this.fb.group({
			offersSearchDefaultRadius: [
				this.settings ? this.settings.offersSearchDefaultRadius : null,
				[Validators.required, Validators.min(0), Validators.max(10000)]
			],
			adminReportEmail: [
				this.settings ? this.settings.adminReportEmail : null,
				[Validators.required, Validators.maxLength(200)]
			],
			vatRate: [
				this.settings ? this.settings.vatRate : null,
				[Validators.required]
			],

			...feeControls,

			categoriesLimit: [
				this.settings ? this.settings.categoriesLimit : null,
				[Validators.required, Validators.min(0), CustomValidators.isInteger]
			],
			servicesLimit: [
				this.settings ? this.settings.servicesLimit : null,
				[Validators.required, Validators.min(0), CustomValidators.isInteger]
			]
		});
	}

	private buildAdministrativeRankControls() {
		return Array.from(
			{ length: this.administrativeFeeRanksNumber },
			(_, index) => {
				const feeKey = `${this.administrativeFeeRankKey}${index}`;
				return {
					key: feeKey,
					[feeKey]: [
						this.settings ? this.settings[feeKey] : null,
						[Validators.required, Validators.min(0), Validators.max(1)]
					]
				};
			}
		);
	}
}
