import { UserResponseDto } from '@models/userResponseDto';
export namespace Utils {
	export function listFromEnum(enm, onlyKeys = true) {
		return onlyKeys
			? Object.keys(enm).filter(k => typeof enm[k as any] === 'number')
			: Object.keys(enm)
					.filter(k => typeof enm[k as any] === 'number')
					.map(item => ({ [enm[item]]: item }));
	}

	/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
	export const UserRole = UserResponseDto.RoleEnum;
	export const MaxUserRating = 5;
}
