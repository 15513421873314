import { ValueObjectDtoOfstring } from '@models/valueObjectDtoOfstring';
import { Injectable } from '@angular/core';
import { ListParameters, ListSort } from '@interfaces/custom/listParameters';
import { ValueObjectDtoOfboolean } from '@models/valueObjectDtoOfboolean';
import { PageOfUserRowDto, UserRowDto } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { SortDirection } from '@swimlane/ngx-datatable';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UsersFilters } from '@interfaces/custom/usersFilters';
import { FileDownloaderService } from '@services/files/file-downloader.service';
import { HttpAuth } from '@services/httpauth/http-auth.service';
import {
	MediaQueryBreakpoint,
	ResponsiveColumn,
	UtilsService
} from '@services/utils/utils.service';
import { ProvidersListComponent } from './providers-list/providers-list.component';
import { map } from 'rxjs/operators';
import { onboardableUserMapper } from '../utils';

export type ProvidersListParameters = ListParameters & UsersFilters;

export interface UserRow extends UserRowDto {
	isActionNeeded: boolean;
}
export interface UsersList extends Omit<PageOfUserRowDto, 'content'> {
	content: UserRow[];
}

@Injectable({
	providedIn: 'root'
})
export class ProvidersService {
	public readonly DEFAULT_LIST_PARAMETERS: ProvidersListParameters = {
		page: 0,
		size: 10,
		sort: [],
		name: ''
	};
	private listParameters: ProvidersListParameters;
	public readonly listParameters$: Subject<ProvidersListParameters> =
		new BehaviorSubject(null);

	getDatatableColumns(component: ProvidersListComponent): ResponsiveColumn[] {
		return this.utilsService.columnsForDatatables([
			{
				name: '',
				resizeable: false,
				sortable: false,
				headerCheckboxable: true,
				checkboxable: true,
				flexGrow: 0
			},
			{
				name: this.translate.instant('users.fullName'),
				prop: 'fullName',
				resizeable: false,
				sortable: true,
				cellTemplate: component.fullNameColumn,
				flexGrow: 1
			},
			{
				name: this.translate.instant('users.email'),
				prop: 'email',
				resizeable: false,
				sortable: false,
				flexGrow: 1
			},
			{
				name: this.translate.instant('users.phoneNumber'),
				prop: 'phoneNumber',
				resizeable: false,
				sortable: false,
				cellTemplate: component.phoneNumberColumn,
				flexGrow: 0.75
			},
			{
				name: this.translate.instant('users.open'),
				prop: 'openJobsCount',
				resizeable: false,
				sortable: true,
				headerTemplate: component.openJobsHeaderTemplate,
				breakpoint: MediaQueryBreakpoint.SmDesktop,
				flexGrow: 0.4,
				headerClass: 'overflow-none'
			},
			{
				name: this.translate.instant('users.confirmed'),
				prop: 'confirmedJobsCount',
				resizeable: false,
				sortable: true,
				breakpoint: MediaQueryBreakpoint.SmDesktop,
				flexGrow: 0.4,
				headerClass: 'overflow-none'
			},
			{
				name: this.translate.instant('users.closed'),
				prop: 'closedJobsCount',
				resizeable: false,
				sortable: true,
				breakpoint: MediaQueryBreakpoint.SmDesktop,
				flexGrow: 0.4,
				headerClass: 'overflow-none'
			},
			{
				name: this.translate.instant('jobs.rating'),
				prop: 'rating',
				resizeable: false,
				sortable: true,
				cellTemplate: component.ratingColumn,
				breakpoint: MediaQueryBreakpoint.LgDesktop,
				flexGrow: 0.5
			},
			{
				name: this.translate.instant('users.jobsCompletedPercentage'),
				prop: 'completedPercent',
				resizeable: false,
				sortable: true,
				cellTemplate: component.jobsCompletedColumn,
				headerClass: 'overflow-none',
				flexGrow: 0.5
			},
			{
				name: this.translate.instant('users.onboardingStatus'),
				prop: 'onboardingStatus',
				resizeable: false,
				sortable: false,
				cellTemplate: component.onboardingStatusColumn,
				headerClass: 'overflow-none',
				flexGrow: 0.5
			},
			{
				name: '',
				resizeable: false,
				sortable: false,
				cellTemplate: component.optionsColumn,
				headerClass: '',
				flexGrow: 0.5,
				frozenRight: true
			}
		]);
	}

	constructor(
		private utilsService: UtilsService,
		private translate: TranslateService,
		private http: HttpAuth,
		private fileDownloader: FileDownloaderService
	) {
		this.listParameters$.subscribe((res: ProvidersListParameters) => {
			this.listParameters = res;
		});
	}

	setListParameters(parameters: ProvidersListParameters) {
		this.listParameters$.next(parameters);
	}

	resetListParameters(overwrite?: ProvidersListParameters) {
		const defaultParameters = Object.assign({}, this.DEFAULT_LIST_PARAMETERS);
		if (overwrite) {
			Object.assign(defaultParameters, overwrite);
		}
		this.listParameters$.next(defaultParameters);
	}

	getProvidersList(): Observable<UsersList> {
		return this.http.get('/users/providers', this.listParameters).pipe(
			map((res: PageOfUserRowDto) => ({
				...res,
				content: res.content.map(user =>
					onboardableUserMapper(user, this.translate)
				)
			}))
		);
	}

	verifyProvider(userId: number): Observable<void> {
		const data: ValueObjectDtoOfboolean = {
			value: true
		};
		return this.http.put(`/users/providers/verify/${userId}`, data);
	}

	updateOnboardingStatus(
		userId: number,
		statusDto: ValueObjectDtoOfstring
	): Observable<void> {
		return this.http.patch(
			`/users/providers/${userId}/onboarding-status`,
			statusDto
		);
	}

	exportToXls() {
		return this.fileDownloader.downloadBlob(
			'/users/providers/export',
			this.listParameters
		);
	}
}
