import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-confirm-modal-component',
	templateUrl: 'confirm-modal.component.html',
	styleUrls: ['confirm-modal.component.scss']
})
export class ConfirmModalComponent {
	title: string;
	description: string;

	constructor(public activeModal: NgbActiveModal) {}
}
