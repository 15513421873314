/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserRowDto {
	blocked?: boolean;
	closedJobsCount?: number;
	completedPercent?: number;
	confirmedJobsCount?: number;
	email?: string;
	fullName?: string;
	id?: number;
	onboardingStatus?: UserRowDto.OnboardingStatusEnum;
	openJobsCount?: number;
	phoneNumber?: string;
	rating?: number;
	spAccountActivated?: boolean;
}
export namespace UserRowDto {
	export type OnboardingStatusEnum =
		| 'REGISTRATION'
		| 'SCREENING_YET_TO_START'
		| 'SCREENING_IN_PROGRESS'
		| 'SCREENING_REJECTED'
		| 'OFFLINE_ONBOARDING_YET_TO_START'
		| 'OFFLINE_ONBOARDING_IN_PROGRESS'
		| 'OFFLINE_ONBOARDING_REJECTED'
		| 'OFFLINE_ONBOARDING_ACCEPTED'
		| 'ONLINE_ONBOARDING_COMPLETED';
	export const OnboardingStatusEnum = {
		REGISTRATION: 'REGISTRATION' as OnboardingStatusEnum,
		SCREENINGYETTOSTART: 'SCREENING_YET_TO_START' as OnboardingStatusEnum,
		SCREENINGINPROGRESS: 'SCREENING_IN_PROGRESS' as OnboardingStatusEnum,
		SCREENINGREJECTED: 'SCREENING_REJECTED' as OnboardingStatusEnum,
		OFFLINEONBOARDINGYETTOSTART:
			'OFFLINE_ONBOARDING_YET_TO_START' as OnboardingStatusEnum,
		OFFLINEONBOARDINGINPROGRESS:
			'OFFLINE_ONBOARDING_IN_PROGRESS' as OnboardingStatusEnum,
		OFFLINEONBOARDINGREJECTED:
			'OFFLINE_ONBOARDING_REJECTED' as OnboardingStatusEnum,
		OFFLINEONBOARDINGACCEPTED:
			'OFFLINE_ONBOARDING_ACCEPTED' as OnboardingStatusEnum,
		ONLINEONBOARDINGCOMPLETED:
			'ONLINE_ONBOARDING_COMPLETED' as OnboardingStatusEnum
	};
}
