import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-info-modal',
	templateUrl: './info-modal.component.html',
	styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent {
	title: string;
	description: string;

	constructor(public activeModal: NgbActiveModal) {}
}
