/**
 * Choresmiths API
 * ## Admin API  - Common [HTTP error responses](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/result-codes-info)  - Common [validation errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/validation-messages-info)  - Common [business errors messages](https://lab4.itcraft.pl:8526/choresmiths-admin-api/swagger/business-errors-codes-info)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Resource category saving data
 */
export interface ResourceCategorySaveDto {
	/**
	 * Required only for editing
	 */
	id?: number;
	/**
	 * Resource category name
	 */
	name: string;
	/**
	 * Type of the resource category
	 */
	type: ResourceCategorySaveDto.TypeEnum;
}
export namespace ResourceCategorySaveDto {
	export type TypeEnum = 'GENERAL' | 'SKILL_BASED' | 'SPONSORED_CONTENT';
	export const TypeEnum = {
		GENERAL: 'GENERAL' as TypeEnum,
		SKILLBASED: 'SKILL_BASED' as TypeEnum,
		SPONSOREDCONTENT: 'SPONSORED_CONTENT' as TypeEnum
	};
}
