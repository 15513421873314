<div class="form-control search-input">
	<img
		src="./../../../assets/images/icons/search.svg"
		width="22"
		height="22"
		class="mr-3"
	/>
	<input
		type="text"
		[placeholder]="placeholder"
		(keyup.enter)="onSearch()"
		(input)="onSearch()"
		[(ngModel)]="searchModel"
	/>
	<img
		*ngIf="showClearButton"
		src="./../../../assets/images/icons/close-gray.svg"
		width="20"
		height="20"
		class="search-input-clear-btn ml-3"
		[ngClass]="{ hidden: !searchModel || !searchModel.length }"
		(click)="clear()"
	/>
</div>
