import { DropdownItem } from '@components/dropdown-field/dropdown-field.component';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserDetailsDto } from '@models/models';
import { HttpAuth } from '@services/httpauth/http-auth.service';
import { Observable } from 'rxjs';
import { onboardableUserMapper } from './utils';

export interface UserDetails
	extends Omit<UserDetailsDto, 'allowedOnboardingStatusTransitions'> {
	isActionNeeded: boolean;
	canChangeOnboardingStatus?: boolean;
	allowedOnboardingStatusTransitions: DropdownItem[];
}

@Injectable({
	providedIn: 'root'
})
export class UsersService {
	constructor(
		private http: HttpAuth,
		private readonly translate: TranslateService
	) {}

	deleteUser(userId: number): Observable<void> {
		return this.http.delete(`/users/${userId}`);
	}

	bulkDeleteUsers(userIds: Array<number>): Observable<void> {
		return this.http.delete('/users', null, {
			list: userIds
		});
	}

	bulkBlockUsers(userIds: Array<number>): Observable<void> {
		return this.http.put('/users/block', {
			list: userIds
		});
	}

	bulkUnblockUsers(userIds: Array<number>): Observable<void> {
		return this.http.put('/users/unblock', {
			list: userIds
		});
	}

	getUserDetails(userId: number): Observable<UserDetails> {
		return this.http
			.get(`/users/${userId}`)
			.pipe(map(user => onboardableUserMapper(user, this.translate)));
	}
}
