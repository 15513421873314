import { LoginUtils } from './../../_services/loginutils/loginutils.service';
import { VersionService, Version } from './version.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LogoutService } from '@services/loginutils/logout.service';
import { Menu } from './../../routes';
import { UserResponseDto } from '@models/models';
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	providers: [VersionService]
})
export class SidebarComponent implements OnInit {
	// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
	public readonly UserRole = UserResponseDto.RoleEnum;
	private readonly userRole = this.loginUtils.user.role;
	public sidebarMenus: Array<Menu> = [
		{
			path: '/users',
			slug: 'manage-users',
			icon: 'person-outline.svg',
			children: [
				{
					path: '/users/providers',
					slug: 'users-providers'
				},
				{
					path: '/users/clients',
					slug: 'users-clients'
				},
				{
					path: '/users/admins',
					slug: 'users-admins',
					visible: this.userRole === this.UserRole.SUPERADMIN
				}
			]
		},
		{
			path: '/services',
			slug: 'manage-services',
			icon: 'format.svg',
			children: [
				{
					path: '/services/categories',
					slug: 'services-categories'
				},
				{
					path: '/services/manage',
					slug: 'services-services'
				}
			]
		},
		{
			path: '/jobs',
			slug: 'manage-jobs',
			icon: 'work.svg',
			children: [
				{ path: '/jobs/list', slug: 'jobs-list' },
				{ path: '/jobs/payments', slug: 'jobs-payments' }
			]
		},
		{
			path: '/resources',
			slug: 'manage-resources',
			icon: 'school.svg',
			children: [
				{ path: '/resources', slug: 'resources-resources' },
				{ path: '/resources/categories', slug: 'resources-categories' }
			]
		},
		{
			path: '/notifications',
			slug: 'manage-notifications',
			icon: 'notifications.svg',
			children: [
				{ path: '/notifications/new', slug: 'notifications-new' },
				{ path: '/notifications/history', slug: 'notifications-history' }
			]
		}
	];

	public readonly brandLogo = {
		size: {
			width: 40,
			height: 40
		},
		source: './../../../assets/images/choresmiths-icon.svg'
	};

	public readonly version: Version = this.versionService.version;
	public readonly isProduction = this.versionService.isProduction;

	get isSettingsScreen() {
		return this.router.url.endsWith('/settings');
	}

	constructor(
		private logoutService: LogoutService,
		private loginUtils: LoginUtils,
		public translate: TranslateService,
		private router: Router,
		private versionService: VersionService
	) {}

	ngOnInit() {
		this.sidebarMenus = this.filterInaccessibleMenus(this.sidebarMenus);
		const menuByPath = this.sidebarMenus.find((menu: Menu) =>
			this.router.url.startsWith(menu.path)
		);
		if (menuByPath) {
			this.toggleExpand(menuByPath);
		}
	}

	toggleExpand(currentMenu: Menu) {
		// Hide all other opened menus
		if (currentMenu.children) {
			this.closeAllMenus();
		} else {
			return;
		}

		currentMenu.opened = !currentMenu.opened;
	}

	visibleMenus = (menu: Menu) => {
		return menu.visible === undefined || menu.visible === true;
	};

	signOut() {
		this.logoutService.logout();
	}

	closeAllMenus() {
		this.sidebarMenus.forEach(menu => (menu.opened = false));
	}

	private filterInaccessibleMenus(menus: Array<Menu>) {
		menus.forEach((menu: Menu, index: number) => {
			if (menu.visible === false) {
				menus.splice(index, 1);
			}
			if (menu.children) {
				this.filterInaccessibleMenus(menu.children);
			}
		});
		return menus;
	}
}
