import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfoModalComponent } from '@components/info-modal/info-modal.component';
import { PagerComponent } from '@components/pager/pager.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CapitalizePipe } from '@pipes/capitalize.pipe';
import { JoinPipe } from '@pipes/join.pipe';
import { TruncatePipe } from '@pipes/truncate.pipe';
import { LoggerService } from '@services/logger/logger.service';
import { ModalService } from '@services/modal/modal.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HasRoleDirective } from 'app/_directives/has-role.directive';
import { TagInputModule } from 'ngx-chips';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfirmModalComponent } from '../../_components/confirm-modal/confirm-modal.component';
import { FileUploaderComponent } from '../../_components/file-uploader/file-uploader.component';
import { FormFieldComponent } from '../../_components/form-field/form-field.component';
import { FormTextFieldComponent } from '../../_components/form-text-field/form-text-field.component';
import { HeaderComponent } from '../../_components/header/header.component';
import { InlineErrorsComponent } from '../../_components/inline-errors/inline-errors.component';
import { ServerErrorsComponent } from '../../_components/server-errors/server-errors.component';
import { LocalCurrencyPipe } from '../../_pipes/local-currency.pipe';
import { LocaleDatePipe } from '../../_pipes/locale-date.pipe';
import { UserDetailsModalComponent } from './../../routes/users/shared/user-details-modal/user-details-modal.component';
import { DropdownFieldComponent } from './../../_components/dropdown-field/dropdown-field.component';
import { SearchInputComponent } from './../../_components/search-input/search-input.component';
import { DestroyableDirective } from './../../_directives/destroyable.directive';
import { NoDataPlaceholderDirective } from './../../_directives/no-data-placeholder.directive';
import { NoCommaPipe } from './../../_pipes/no-comma.pipe';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbModule,
		NgxDatatableModule,
		TagInputModule,
		NgSelectModule,
		InfiniteScrollModule
	],
	entryComponents: [
		ConfirmModalComponent,
		InfoModalComponent,
		UserDetailsModalComponent
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbModule,
		NgxDatatableModule,
		ServerErrorsComponent,
		FormTextFieldComponent,
		FormFieldComponent,
		FileUploaderComponent,
		HeaderComponent,
		NgSelectModule,
		TagInputModule,
		InfiniteScrollModule,
		ConfirmModalComponent,
		InfoModalComponent,
		UserDetailsModalComponent,
		LocaleDatePipe,
		LocalCurrencyPipe,
		JoinPipe,
		CapitalizePipe,
		TruncatePipe,
		NoCommaPipe,
		HasRoleDirective,
		NoDataPlaceholderDirective,
		PagerComponent,
		SearchInputComponent,
		DropdownFieldComponent,
		DestroyableDirective
	],
	providers: [LoggerService, ModalService],
	declarations: [
		ServerErrorsComponent,
		FormTextFieldComponent,
		FormFieldComponent,
		HeaderComponent,
		FileUploaderComponent,
		InlineErrorsComponent,
		ConfirmModalComponent,
		InfoModalComponent,
		UserDetailsModalComponent,
		LocaleDatePipe,
		LocalCurrencyPipe,
		JoinPipe,
		CapitalizePipe,
		TruncatePipe,
		NoCommaPipe,
		HasRoleDirective,
		NoDataPlaceholderDirective,
		PagerComponent,
		SearchInputComponent,
		DropdownFieldComponent,
		DestroyableDirective
	]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule
		};
	}
}
