<app-header name="{{ 'settings.title' | translate }}"> </app-header>
<div class="settings-container" *ngIf="settingsForm">
	<form [formGroup]="settingsForm" class="form-group">
		<div class="d-flex flex-column">
			<h5 class="mb-3">{{ 'settings.sections.general' | translate }}</h5>
			<section class="row mx-0 general-settings">
				<app-form-field
					[form]="settingsForm"
					[serverErrors]="formErrors"
					class="col-12 col-lg-6 mt-3 mt-lg-0"
					field="offersSearchDefaultRadius"
				>
					<label for="offersSearchDefaultRadius">
						{{ 'settings.offerSearchRadius' | translate }}
					</label>
					<div class="input-group">
						<input
							id="offersSearchDefaultRadius"
							class="form-control"
							type="number"
							formControlName="offersSearchDefaultRadius"
						/>
						<div class="input-group-append">
							<span class="input-group-text">{{
								'settings.offerSearchRadiusUnit' | translate
							}}</span>
						</div>
					</div>
				</app-form-field>
				<app-form-field
					[form]="settingsForm"
					[serverErrors]="formErrors"
					field="adminReportEmail"
					class="col-12 col-lg-6"
				>
					<label for="adminReportEmail">
						{{ 'settings.adminReportEmail' | translate }}
					</label>
					<input
						id="adminReportEmail"
						class="form-control"
						type="email"
						formControlName="adminReportEmail"
					/>
				</app-form-field>
			</section>

			<h5 class="mt-3">{{ 'settings.sections.payments' | translate }}</h5>
			<section class="row mx-0 payments-settings">
				<app-form-field
					[form]="settingsForm"
					[serverErrors]="formErrors"
					class="col-12 col-lg-6 mt-3"
					field="vatRate"
				>
					<label for="vatRate">
						{{ 'settings.vatRate' | translate }}
					</label>
					<input
						id="vatRate"
						class="form-control"
						type="number"
						formControlName="vatRate"
						step="0.001"
						min="0"
						max="1"
					/>
				</app-form-field>
				<ng-container
					*ngFor="let control of administrativeFeeControls; let i = index"
				>
					<app-form-field
						[form]="settingsForm"
						[serverErrors]="formErrors"
						[field]="'administrativeFeeRank' + i"
						class="col-12 col-lg-6 mt-3"
					>
						<label [for]="'administrativeFeeRank' + i">
							{{ 'settings.administrativeFeeRank' | translate }} {{ i }}
						</label>
						<input
							[attr.id]="'administrativeFeeRank' + i"
							class="form-control"
							type="number"
							[formControlName]="'administrativeFeeRank' + i"
							step="0.001"
							min="0"
							max="1"
						/>
					</app-form-field>
				</ng-container>
			</section>

			<h5 class="mt-3">{{ 'settings.sections.services' | translate }}</h5>
			<section class="row mx-0 services-settings">
				<app-form-field
					[form]="settingsForm"
					[serverErrors]="formErrors"
					class="col-12 col-lg-6 mt-3"
					field="categoriesLimit"
				>
					<label for="categoriesLimit">
						{{ 'settings.categoriesLimit' | translate }}
					</label>
					<input
						id="categoriesLimit"
						class="form-control"
						type="number"
						formControlName="categoriesLimit"
						min="0"
						step="1"
					/>
				</app-form-field>
				<app-form-field
					[form]="settingsForm"
					[serverErrors]="formErrors"
					class="col-12 col-lg-6 mt-3"
					field="servicesLimit"
				>
					<label for="servicesLimit">
						{{ 'settings.servicesLimit' | translate }}
					</label>
					<input
						id="servicesLimit"
						class="form-control"
						type="number"
						formControlName="servicesLimit"
						min="0"
						step="1"
					/>
				</app-form-field>
			</section>
		</div>
	</form>
	<div class="d-flex w-100">
		<button
			class="btn btn-primary upper ml-auto mr-3"
			(click)="submitForm()"
			[disabled]="!settingsForm?.valid || submitPending"
		>
			{{ 'common.save' | translate }}
		</button>
	</div>
</div>
