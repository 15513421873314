<!-- <nav class="navbar navbar-dark navbar-expand-lg fixed-top" id="mainnav">
	<button
		class="btn mr-3 toggleLeft"
		id="togglerBtn"
		[class.btn-primary-darken]="toggleLeft"
		[class.btn-dark]="!toggleLeft"
		(mouseover)="toggleLeftSticky = true"
		(mouseleave)="toggleLeftSticky = false"
		(click)="toggle()"
	>
		<i class="fa fa-navicon"></i>
	</button>
	<a class="navbar-brand" routerLink="/home">{{ appName }}</a>
	<button
		class="navbar-toggler d-lg-none collapsed"
		type="button"
		aria-expanded="false"
		(click)="toggleTop = !toggleTop"
		[attr.aria-label]="'general.toggleNavigation' | translate"
	>
		<span class="navbar-toggler-icon"></span>
	</button>

	<div class="navbar-collapse collapse" [class.collapse]="!toggleTop">
		<div ngbDropdown class="ml-auto" placement="bottom-right">
			<div class="avatar d-inline-block mr-3">
				<div class="justify-content-center align-self-center">
					<img class="u-36-avatar rounded-circle" [src]="user.avatarUrl" />
				</div>
			</div>

			<button
				class="btn btn-outline-primary dropdown-toggle"
				id="dropdownBasic1"
				ngbDropdownToggle
			>
				<span class="mr-2">( {{ 'roles.' + user.role | translate }} )</span>
				<span class="text-muted">{{ user.username }}</span>
			</button>

			<div
				ngbDropdownMenu
				class="dropdown-menu"
				aria-labelledby="dropdownBasic1"
			>
				<button class="dropdown-item" routerLink="/profile">
					{{ 'login.profile' | translate }}
				</button>
				<button class="dropdown-item" (click)="logout.logout()">
					{{ 'login.logout' | translate }}
				</button>
			</div>
		</div>
	</div>
</nav>

<aside
	class="col-sm-4 sidebar bg-primary"
	(mouseover)="toggleLeftSticky = true"
	(mouseleave)="toggleLeftSticky = false"
	[class.toggledSticky]="toggleLeftSticky"
	[class.pinned]="!toggleLeft"
	[class.hidden]="toggleLeft && !toggleLeftSticky"
>
	<app-sidebar></app-sidebar>
</aside>

<div class="container-fluid" id="main">
	<div class="row m-0">
		<nav class="col-sm-2 col-md-2 d-none bg-light sidebar hide">
			<ul class="nav nav-pills flex-column">
				<li class="nav-item">
					<a class="nav-link active" href="#"
						>{{ 'sidebar.overview' | translate }}
						<span class="sr-only">({{ 'common.current' | translate }})</span>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="#">{{ 'sidebar.reports' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="#">{{ 'sidebar.analytics' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="#">{{ 'sidebar.export' | translate }}</a>
				</li>
			</ul>
		</nav>

		<main
			class="container-fluid p-0"
			[ngClass]="{ 'col-sm-12': toggleLeft }"
			role="main"
			(click)="togglemain($event)"
		>
			<router-outlet></router-outlet>
		</main>
	</div>
</div>

<footer id="mainfooter">
	<span class="text-center text-muted mt-4 d-block">{{
		'general.copyrights' | translate
	}}</span>
</footer> -->

<div class="d-flex" style="min-height: 100vh">
	<aside class="col-sm-4 fixed-sidebar px-0">
		<app-sidebar></app-sidebar>
	</aside>
	<main class="col m-4 px-0 content-container">
		<router-outlet></router-outlet>
	</main>
</div>
