import { IsSuperadminGuard } from './_guards/is-superadmin.guard';
import { Routes } from '@angular/router';
import { LayoutComponent } from '@components/layout/layout.component';
import { LoginComponent } from './routes/pages/login/login.component';
import { IsLoggedGuard } from '@guards/is-logged.guard';
import { Page403Component } from './routes/pages/page403/page403.component';
import { PrivacyPolicyComponent } from '@routes/pages/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from '@routes/pages/terms-of-use/terms-of-use.component';
import { SettingsComponent } from '@routes/pages/settings/settings.component';

export const routes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [IsLoggedGuard],
		children: [
			{ path: '', redirectTo: 'users', pathMatch: 'full' },
			{
				path: 'users',
				loadChildren: () =>
					import('@routes/users/users.module').then(m => m.UsersModule)
			},
			{
				path: 'services',
				loadChildren: () =>
					import('@routes/services/services.module').then(m => m.ServicesModule)
			},
			{
				path: 'jobs',
				loadChildren: () =>
					import('@routes/jobs/jobs.module').then(m => m.JobsModule)
			},
			{
				path: 'resources',
				loadChildren: () =>
					import('@routes/resources/resources.module').then(
						m => m.ResourcesModule
					)
			},
			{
				path: 'notifications',
				loadChildren: () =>
					import('@routes/notifications/notifications.module').then(
						m => m.NotificationsModule
					)
			},
			{
				path: 'settings',
				component: SettingsComponent,
				canActivate: [IsSuperadminGuard]
			}
		]
	},
	{ path: 'login', component: LoginComponent },
	{ path: '403', component: Page403Component },
	{ path: 'password/reset/:token', component: LoginComponent },
	{ path: 'privacy-policy', component: PrivacyPolicyComponent },
	{ path: 'terms-of-use', component: TermsOfUseComponent },
	{ path: '**', redirectTo: '' }
];
export interface Menu {
	path?: string;
	slug: string;
	opened?: boolean;
	visible?: boolean;
	children?: Array<Menu>;
	icon?: string;
}
