import { Subject } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';

@Directive({
	selector: '[appDestroyable]'
})
export class DestroyableDirective implements OnDestroy {
	private _destroyed$: Subject<void> = new Subject();

	get destroyed$() {
		return this._destroyed$.asObservable();
	}

	constructor() {}

	ngOnDestroy() {
		this._destroyed$.next();
	}
}
