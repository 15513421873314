<div class="container">
	<div class="d-flex p-3 justify-content-center align-items-center login-block">
		<div class="flex-column col-sm-8 col-md-5">
			<div class="logo row justify-content-center mb-5">
				<img
					src="./../../../../assets/images/choresmiths-logo.svg"
					alt="choresmiths-logo"
				/>
			</div>
			<div
				class="login-form"
				*ngIf="!showPasswordReset && !passwordResetResult"
			>
				<form [formGroup]="loginForm" (submit)="submit($event)">
					<div class="form-group">
						<input
							type="text"
							class="form-control"
							formControlName="username"
							id="username"
							placeholder="{{ 'login.loginPage.email' | translate }}"
						/>
					</div>

					<div class="form-group">
						<input
							type="password"
							class="form-control"
							id="password"
							formControlName="password"
							placeholder="{{ 'login.loginPage.password' | translate }}"
						/>
					</div>

					<button
						class="btn btn-primary w-100 mt-2"
						[disabled]="pending || loginForm.invalid"
					>
						{{ 'login.signIn' | translate }}
					</button>
				</form>
			</div>
			<div class="password-reset-form" *ngIf="showPasswordReset">
				<h4 class="mb-3">
					{{ 'login.setNewPasswordPage.title' | translate }}
				</h4>
				<form
					[formGroup]="passwordResetForm"
					(submit)="submitPasswordReset($event)"
				>
					<app-form-field
						[form]="passwordResetForm"
						[serverErrors]="passwordResetFormErrors"
						field="newPassword"
					>
						<div class="form-group">
							<label for="newPassword" class="text-muted">{{
								'login.setNewPasswordPage.password' | translate
							}}</label>
							<input
								type="password"
								class="form-control"
								formControlName="newPassword"
								id="newPassword"
								autocomplete="new-password"
							/>
						</div>
					</app-form-field>
					<div class="password-hint mt-2">
						<p class="mb-1">
							{{ 'login.setNewPasswordPage.passwordHint' | translate }}:
						</p>
						<ul>
							<li *ngFor="let passwordRequirement of passwordRequirements">
								{{ passwordRequirement }}
							</li>
						</ul>
					</div>
					<button
						class="btn btn-primary w-100 mt-2"
						[disabled]="
							passwordResetResult || pending || passwordResetForm.invalid
						"
					>
						{{ 'login.setNewPasswordPage.submit' | translate }}
					</button>
				</form>
			</div>
			<div *ngIf="!showPasswordReset && passwordResetResult">
				<h4 class="mb-3 text-center">
					{{ 'login.setNewPasswordPage.success' | translate }}
				</h4>
			</div>
			<div
				class="card mt-3 text-white bg-danger p-1"
				*ngIf="loginError && !pending"
			>
				<div class="text-center">{{ loginError | translate }}</div>
			</div>
		</div>
	</div>
</div>
