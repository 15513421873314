import { Injectable } from '@angular/core';
import { VERSION } from './../../../environments/version';
import { environment } from '@env';

export interface Version {
	dirty?: boolean;
	raw?: string;
	hash?: string;
	distance?: unknown;
	tag?: unknown;
	semver?: unknown;
	suffix?: string;
	semverString?: string;
	version: string;
	buildDate?: string;
}

@Injectable()
export class VersionService {
	get version(): Version {
		return VERSION;
	}

	get isProduction() {
		return !!environment.production;
	}

	constructor() {}
}
