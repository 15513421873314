import { TranslateService } from '@ngx-translate/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { LogoutService } from '../loginutils/logout.service';
import { LoggerService } from '@services/logger/logger.service';

interface TheError extends HttpErrorResponse {
	id?: Symbol;
}

@Injectable()
export class ErrorsService {
	// @TODO: Ten serwis powinien zawierać tylko i wyłącznie metody do obsługi błędów - reszta powinna dziedziczyć z @itcraft/httpclient
	errors: Map<Symbol, any> = new Map();
	url = new URL(location.href);

	// codes: Map<string | number, Function> = new Map();

	constructor(
		private log: LoggerService,
		private logout: LogoutService,
		private translate: TranslateService,
		private toastr: ToastrService
	) {
		// this.log.debug('ErrorsService', this);
	}

	handleError(err: TheError): Observable<any> {
		const id = Symbol('error');
		err.id = id;
		this.errors.set(id, this.handler(err));
		return observableThrowError(this.errors.get(id));
	}

	handler(err: HttpErrorResponse) {
		const method = `code${err.status}`;
		return err.status && !!this[method]
			? this[method](err)
			: this.defaultHandler(err);
	}

	defaultHandler(err) {
		const msg = err.status
			? `No error code method implemented for ${err.status}`
			: `No error code`;
		this.log.error(msg, err);
		return err;
	}

	code400(err) {
		try {
			const e = JSON.parse(err.error);
			this.toastr.error(e.errorDetails, e.error);
		} catch (e) {}
		return err;
	}

	code401(err) {
		const split = err.url.split('/');
		if (split[split.length - 1] === 'login') return err;
		if (
			err.url &&
			(split[split.length - 1] !== 'logout' &&
				split[split.length - 1] !== 'logout')
		)
			this.logout.logout();
		return err;
	}

	code409(err) {
		try {
			// const e = JSON.parse( err.error );
			// this.toastr.error(e.errorDetails, e.error);
			this.log.error('409', err.error);
		} catch (e) {}
		return err;
	}

	handleGlobalError(
		error: any,
		actionTranslationKey?: string,
		overrideTitle?: string
	) {
		const toastTitle: string = overrideTitle
			? overrideTitle
			: this.translate.instant('globalErrors.serverError');
		let toastMessage: string;
		if (error.error) {
			toastMessage = this.translate.instant(
				`globalErrors.${error.error.message}`
			);
		} else {
			if (actionTranslationKey) {
				toastMessage = this.translate.instant('globalErrors.errorOccurred', {
					action: this.translate.instant(`actions.${actionTranslationKey}`)
				});
			} else {
				toastMessage = this.translate.instant('globalErrors.unknownError');
			}
		}

		console.error(error);
		this.toastr.error(toastMessage, toastTitle);
	}
}
